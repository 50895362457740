import React from 'react';
import PropTypes from 'prop-types';
import { getSVCFee } from '../../helpers/svcFeeAmount';
import classes from './PaymentInfo.module.scss';

const totalCalculation = (data) => {
  const convenienceFee = ((data.virtualTerminalSettings.InvoiceSVCFee / 100) * data.amount).toFixed(2);
  const salesTaxAmount = ((data.virtualTerminalSettings.InvoiceSalesTaxFee / 100) * data.amount).toFixed(2);
  return (data.amount + parseFloat(convenienceFee) + parseFloat(salesTaxAmount)).toFixed(2);
};

export const PaymentInfo = ({ style, data }) => {
  let InvoiceDiscount = data?.virtualTerminalSettings.InvoiceDiscount ?? 0;
  let InvoiceSVCFee = data?.virtualTerminalSettings.InvoiceSVCFee ?? 0;
  let InvoiceSVCFeeType = data?.virtualTerminalSettings.InvoiceSVCFeeType ?? '';
  let InvoiceTax = data?.virtualTerminalSettings.InvoiceTax ?? '';
  let InvoiceSalesTaxFee = data?.virtualTerminalSettings.InvoiceSalesTaxFee ?? 0;
  let InvoiceSalesTaxFeeType = data?.virtualTerminalSettings.InvoiceSalesTaxFeeType ?? '';

  const isDualPricingEnabled = () => !!data.DualFeeEnabled;
  const isTaxEnabled = () => !!InvoiceTax;
  const getCardSubtotal = () =>
    Number(data.amount) + (InvoiceDiscount ? Number(getSVCFee(InvoiceSVCFeeType, InvoiceSVCFee, data.amount)) : 0);

  return (
    <div className="payment-info" style={style}>
      <span className={classes.textAlignLeft}>
        {' '}
        Hi <strong> {data.firstName || data.customerId?.firstName || ''}, </strong>{' '}
      </span>{' '}
      <br /> <br />
      <span className={classes.textAlignCenter}>
        Your truck and rate are reserved for the time above. <br /> <br />
        Please note other customers also need our assistance, and your rate may change once the time expires. <br />
      </span>{' '}
      <br />
      <div>
        {isDualPricingEnabled() ? (
          <div className={classes.paymentInfo}>
            <div className={classes.leftSection}>
              {isTaxEnabled() && (
                <>
                  <div>Cash Amount: ${parseFloat(data.amount).toFixed(2)}</div>
                  <div>Sales Tax: ${getSVCFee(InvoiceSalesTaxFeeType, InvoiceSalesTaxFee, data.amount)}</div>
                </>
              )}
              <div>Cash Total: ${parseFloat(data.cashTotal).toFixed(2)} </div>
            </div>
            <div className={classes.rightSection}>
              <div>
                {isTaxEnabled() && (
                  <>
                    <div>Card Amount: ${getCardSubtotal()}</div>
                    <div>Sales Tax: ${getSVCFee(InvoiceSalesTaxFeeType, InvoiceSalesTaxFee, getCardSubtotal())}</div>
                  </>
                )}
                <div>Card Total: ${data.total}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.textAlignRight}>
            <strong className={classes.textAlignRight}>Subtotal: ${parseFloat(data.amount).toFixed(2)}</strong>
            <br />
            <strong className={classes.textAlignRight}>
              Convenience Fee: $
              {parseFloat((data.virtualTerminalSettings.InvoiceSVCFee / 100) * data.amount).toFixed(2)}
            </strong>{' '}
            <br />
            <strong className={classes.textAlignRight}>
              Sales Tax: ${parseFloat((data.virtualTerminalSettings.InvoiceSalesTaxFee / 100) * data.amount).toFixed(2)}
            </strong>{' '}
            <br />
            <strong>Total Amount Due: ${totalCalculation(data)}</strong>
          </div>
        )}
      </div>
    </div>
  );
};

PaymentInfo.propTypes = {
  style: PropTypes.object,
  data: PropTypes.object,
};

export default PaymentInfo;
