import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { LocationDetailByLink, CustomerCreateUserDetailByLink } from '../../actions/AdminUserAction';
import classes from './User.module.scss';
import { Buttons } from '../../components/Buttons';
import LoadingOverlay from 'react-loading-overlay';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validator from '../../helpers/checkValidations';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getEnvironmentVariablesList } from '../../actions/EnvironmentVariablesAction';
import { message } from '../../constants';
import { isNotNullOrUndefined } from '../../helpers/util';
const axios = require('axios');

const ERROR_MESSAGE = 'Either the link has been expired or there is Something is wrong, Kindly contact admin.';

ErrorDisplay.propTypes = {
  hasError: PropTypes.bool,
  className: PropTypes.string,
  message: PropTypes.string,
};

const styles = {
  imageStyle: { maxWidth: '95%', marginBottom: '10px' },
};

function ErrorDisplay({ hasError, className, message }) {
  return hasError ? <div className={className}>{message}</div> : '';
}

export default function SelfCustomerCreation() {
  const dispatch = useDispatch();
  const EnvironmentVariablesReducer = useSelector((state) => state.EnvironmentVariablesReducer);
  const [paytraceAwsPath, setPaytraceAwsPath] = useState(null);
  const { locationId } = useParams();
  const [data, setData] = useState({
    location: { image: false },
    company: { image: false },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState({
    error: false,
    errorMessage: '',
  });
  const [isMessage, setIsMessage] = useState({
    messageAvailable: false,
    message: '',
    type: 'success',
  });
  const [companyImageUrl, setCompanyImageUrl] = useState(paytraceAwsPath + 'titanium-logo.png');
  const [isUserCreated, setIsUserCreated] = useState(false);
  const { imageStyle } = styles;

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required'),
    phone: Yup.string(),
    userName: Yup.string().min(3, 'Username must be at least 3 characters').required('Username is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  useEffect(() => {
    dispatch(getEnvironmentVariablesList());
    setIsLoading(true);
    LocationDetailByLink({ id: locationId })
      .then((res) => {
        if (res?.payload?.userData?.data?.success === true) {
          const response = res?.payload?.userData?.data?.response;
          setData({ ...response });
        }
        if (res.type === 'USER_ERROR') {
          setIsError({
            error: true,
            errorMessage: ERROR_MESSAGE,
          });
        }
        setIsLoading(false);
      })
      .catch((_err) => {
        setIsError({
          error: true,
          errorMessage: ERROR_MESSAGE,
        });
        console.log(_err);
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      userName: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const email = validator('email', values.email);
      if (email) {
        setIsMessage({
          messageAvailable: true,
          message: 'Provide a Valid Email.',
          type: 'error',
        });
        setIsLoading(false);
        return false;
      }
      values.locationId = locationId;
      values.companyId = data?.company;
      values.customerUserCreation = true;
      values.isStaticActivationRequired = true;
      console.log('Form data:', values);
      setIsLoading(true);
      const response = await CustomerCreateUserDetailByLink(values);
      if (response.type === 'USER_SUCCESS') {
        console.log(response);
        setIsUserCreated(true);
        setIsMessage({
          messageAvailable: true,
          message: 'Your customer portal account has been successfully created. Please check your email to activate!',
          type: 'success',
        });
        setIsLoading(false);
      }
      if (response.payload.error.data.success === false) {
        setIsMessage({
          messageAvailable: true,
          message: response.payload.error.data.message,
          type: 'error',
        });
      }
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (isNotNullOrUndefined(data?.image)) {
      setCompanyImageUrl(paytraceAwsPath + data?.image);
    } else {
      setCompanyImageUrl(paytraceAwsPath + 'titanium-logo.png');
    }
  }, [data]);

  useEffect(() => {
    console.log('EnvironmentVariablesReducer', EnvironmentVariablesReducer);
    if (EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH) {
      setPaytraceAwsPath(EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH);
    }
  }, [EnvironmentVariablesReducer]);

  const redirectLogin = () => {
    window.location.href = '/login';
  };

  const hasError = (tVar, eVar) => {
    return tVar && eVar;
  };

  const getClasses = (formik, field) => {
    return `${classes.signUpInput} ${formik.touched[field] && formik.errors[field] ? classes.errorHighlight : ''}`;
  };

  return (
    <div className={classes.userLink}>
      <LoadingOverlay active={isLoading} spinner text="please wait...">
        <div className={classes.paper}>
          {isError.error ? '' : <img src={companyImageUrl} style={imageStyle} height={'100rem'} alt="logo" />}
          {isError.error ? (
            <div className={classes.error}>{isError.errorMessage}</div>
          ) : (
            <>
              {isMessage.messageAvailable ? (
                <div className={isMessage.type === 'error' ? classes.error : 'success'}>{isMessage.message}</div>
              ) : (
                ''
              )}
              {isUserCreated ? (
                <Buttons
                  id="login"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submitButtonTopper}
                  onClick={redirectLogin}
                  text="Click Here to Login"
                />
              ) : (
                <div className={classes.signupFormContainer}>
                  <h2 className={classes.formH3}>Create a Custormer Portal Account</h2>
                  <form onSubmit={formik.handleSubmit} className="classes.signupForm">
                    <div className={classes.formGroup}>
                      <label required htmlFor="firstName" className={classes.labelSignup}>
                        First Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                        className={getClasses(formik, 'firstName')}
                      />
                      <ErrorDisplay
                        hasError={hasError(formik.touched.firstName, formik.errors.firstName)}
                        className={classes.errorMessage}
                        message={formik.errors.firstName}
                      />
                    </div>

                    <div className={classes.formGroup}>
                      <label htmlFor="lastName" className={classes.labelSignup}>
                        Last Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                        className={getClasses(formik, 'lastName')}
                      />
                      <ErrorDisplay
                        hasError={hasError(formik.touched.lastName, formik.errors.lastName)}
                        className={classes.errorMessage}
                        message={formik.errors.lastName}
                      />
                    </div>

                    <div className={classes.formGroup}>
                      <label htmlFor="email" className={classes.labelSignup}>
                        Email <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        className={getClasses(formik, 'email')}
                      />
                      <ErrorDisplay
                        hasError={hasError(formik.touched.email, formik.errors.email)}
                        className={classes.errorMessage}
                        message={formik.errors.email}
                      />
                    </div>

                    <div className={classes.formGroup}>
                      <label htmlFor="phone" className={classes.labelSignup}>
                        Phone
                      </label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                        className={getClasses(formik, 'phone')}
                      />
                      <ErrorDisplay
                        hasError={hasError(formik.touched.phone, formik.errors.phone)}
                        className={classes.errorMessage}
                        message={formik.errors.phone}
                      />
                    </div>

                    <div className={classes.formGroup}>
                      <label htmlFor="userName" className={classes.labelSignup}>
                        Username <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="userName"
                        name="userName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.userName}
                        className={getClasses(formik, 'userName')}
                      />
                      <ErrorDisplay
                        hasError={hasError(formik.touched.userName, formik.errors.userName)}
                        className={classes.errorMessage}
                        message={formik.errors.userName}
                      />
                    </div>

                    <div className={classes.formGroup}>
                      <label htmlFor="password" className={classes.labelSignup}>
                        Password <span>*</span>
                      </label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        className={getClasses(formik, 'password')}
                      />
                      <ErrorDisplay
                        hasError={hasError(formik.touched.password, formik.errors.password)}
                        className={classes.errorMessage}
                        message={formik.errors.password}
                      />
                    </div>

                    <div className={classes.formGroup}>
                      <label htmlFor="confirmPassword" className={classes.labelSignup}>
                        Confirm Password <span>*</span>
                      </label>
                      <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                        className={getClasses(formik, 'confirmPassword')}
                      />
                      <ErrorDisplay
                        hasError={hasError(formik.touched.confirmPassword, formik.errors.confirmPassword)}
                        className={classes.errorMessage}
                        message={formik.errors.confirmPassword}
                      />
                    </div>

                    <button type="submit" className={classes.submitButton}>
                      Submit
                    </button>
                  </form>
                </div>
              )}
            </>
          )}
        </div>
      </LoadingOverlay>
    </div>
  );
}
