import React, { Component } from 'react';
import { cardTotalAmountWhenDualPricingIsEnabled, getRoadsideTotalAmount, getSVCFee } from '../../helpers/svcFeeAmount';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { RenderMenuItem } from './../../components/RenderMenuItem';
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Topheader } from './../../components/Topheader';
import { Textfield } from './../../components/Textfield';
import classes from './InvoiceEdit.module.scss';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { InvoiceDetailById, setInputState, intakeInvoiceLink, updateFormData } from './../../actions/RequestFormAction';
import LoadingOverlay from 'react-loading-overlay';
import { Buttons } from './../../components/Buttons';

const initialState = { valid: false };

const getStopValue = (event, value) => {
  let stop = null;
  switch (event.target.id) {
    case 'phoneNumber':
      if (value.length > 10 || isNaN(value)) {
        stop = true;
      }
      break;
    case 'zip':
      if (value.length > 5) {
        stop = true;
      }
      break;
    default:
      stop = false;
  }
  return stop;
};

class InvoiceEdit extends Component {
  state = initialState;
  constructor(props) {
    super(props);
    this.state = {
      invoice: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      year: '',
      make: '',
      model: '',
      color: '',
      serviceType: '',
      problemType: '',
      willAnyoneBeWithTheVehicle: '',
      doYouHaveKeysForVehicle: '',
      regularGasOrDiesel: '',
      neutral: '',
      doAllWheelsTurn: '',
      doesFrontWheelsTurn: '',
      doesBackWheelsTurn: '',
      pickUpLocation: '',
      pickUpNotes: '',
      startAddress: '',
      endAddress: '',
      email: '',
      amount: '',
      basePrice: '',
      notes: '',
      sendTo: 'phone',
      zipForCalc: '',
      system: '',
      resendTimeText: '',
      history: {},
      misMatchError: [],
      responseError: false,
      sideShow: true,
      isLoading: true,
      error: {
        passwordError: false,
        userNameError: false,
      },
    };
  }
  UNSAFE_componentWillMount() {
    let user = JSON.parse(localStorage.getItem('user'));
    let locationDetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    if (user.token) {
      const {
        match: { params },
      } = this.props;
      this.props.InvoiceDetailById({ id: params.id }).then((result) => {
        this.setState({ ...result.payload.value, isLoading: false });
      });
      //redirect to intake form for road assitence client
      if (locationDetails?.customDefaultBuild) {
        this.props.history.push('/AddInvoices');
      }
    } else {
      this.props.history.push('/Login');
    }
  }

  handleSubmitEmailInvoice(e, _t) {
    e.preventDefault();
    let misMatchError = [];
    let successMessages = false;
    // console.log('>>>',this.props.input)
    // return false;
    this.setState({ isSubmit: true, isLoading: true });

    this.props.intakeInvoiceLink(this.props.input).then((result) => {
      window.scrollTo(0, 0, 0);
      if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
        this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
          setTimeout(() => {
            this.setState({ successMessages: false });
          }, 3000);
        });
      }
      if (result !== undefined && result.type === 'INVOICE_ERROR') {
        let message = result.payload.error.data.message;
        misMatchError.push(message);
        this.setState({ misMatchError, isLoading: false, successMessages }, () => {
          setTimeout(() => {
            let misMatchError = [];
            this.setState({ misMatchError });
          }, 3000);
        });
      }
    });
  }
  handleUpdate(e) {
    let misMatchError = [];
    let successMessages = false;
    this.setState({ isSubmit: true });
    e.preventDefault();

    this.props.updateFormData(this.props.input).then((result) => {
      window.scrollTo(0, 0);
      if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
        this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
          setTimeout(() => {
            this.setState({ successMessages: false });
            this.setState({ redirectTransaction: true });
          }, 2000);
        });
      }
      if (result !== undefined && result.type === 'INVOICE_ERROR') {
        if (result.payload.error.data && result.payload.error.data.statusCode === 403) {
          let message = result.payload.error.data.message;
          misMatchError.push(message);
          this.setState({ isLoading: false, misMatchError, successMessages });
        }

        this.setState({ isLoading: false, misMatchError, successMessages });
      }
    });
  }

  textHandler(event, key = null) {
    const { setInputState, input } = this.props;
    let misMatchError = [];
    this.setState({ misMatchError });
    let value = event.target.value.toString();
    let stop = getStopValue(event, value);

    if (!event.target.id) {
      setInputState('amount', event.target.value);
    } else {
      if (stop) setInputState(event.target.id, input[event.target.id]);
      else {
        const { InvoiceDiscount } = this.props.UserDetail?.settings.virtualTerminalSettings ?? 0;
        let total = parseFloat(event.target.value);
        if (event.target.id === 'amount' && InvoiceDiscount) {
          const InvoiceSVCFee = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSVCFee ?? 0;
          const InvoiceSVCFeeType = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSVCFeeType ?? '';
          const InvoiceSalesTaxFee = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSalesTaxFee ?? 0;
          const InvoiceSalesTaxFeeType =
            this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSalesTaxFeeType ?? '';
          const svcFee = getSVCFee(InvoiceSVCFeeType, InvoiceSVCFee, parseFloat(event.target.value));
          const tax = getSVCFee(InvoiceSalesTaxFeeType, InvoiceSalesTaxFee, parseFloat(event.target.value));
          total = (total + parseFloat(svcFee) + parseFloat(tax)).toFixed(2);
          setInputState('tax', tax);
          setInputState('amount', event.target.value);
          setInputState('svcFee', svcFee);
          setInputState('total', total);
        } else if (!InvoiceDiscount) {
          setInputState('svcFee', '');
        }
        setInputState(event.target.id, value, key);
      }
    }
  }

  isDualPricingEnabled() {
    return this.props.UserDetail?.settings?.DualFeeEnabled;
  }

  cardTotalAmountWhenDualPricingIsEnabled() {
    const InvoiceDiscount = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceDiscount ?? 0;
    let InvoiceTax = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceTax ?? '';
    const InvoiceSVCFee = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSVCFee ?? 0;
    const InvoiceSVCFeeType = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSVCFeeType ?? '';
    const InvoiceSalesTaxFee = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSalesTaxFee ?? 0;
    const InvoiceSalesTaxFeeType =
      this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSalesTaxFeeType ?? '';

    const cardSubTotal =
      Number(this.props.input.amount) +
      (InvoiceDiscount ? Number(getSVCFee(InvoiceSVCFeeType, InvoiceSVCFee, this.props.input.amount)) : 0);
    return (
      Number(cardSubTotal) +
      (InvoiceTax ? Number(getSVCFee(InvoiceSalesTaxFeeType, InvoiceSalesTaxFee, cardSubTotal)) : 0)
    );
  }
  // textHandlerCustom(event, key = null) {
  //   const setInputState = this.props;
  //   let value = event.target.value.toString();
  //   setInputState(event.target.id, value, key);
  // }

  render() {
    const locationDetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    let InvoiceDiscount = 0;
    let InvoiceTax = 0;
    let InvoiceSVCFee = 0;
    let InvoiceSVCFeeType = '';
    let DiscountFeeName = '';
    let InvoiceSalesTaxFee = 0;
    let InvoiceSalesTaxFeeType = '';

    if (
      this.props.UserDetail &&
      this.props.UserDetail?.settings &&
      this.props.UserDetail?.settings?.virtualTerminalSettings
    ) {
      InvoiceDiscount = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceDiscount ?? 0;
      InvoiceTax = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceTax ?? '';
      InvoiceSVCFee = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSVCFee ?? 0;
      InvoiceSVCFeeType = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSVCFeeType ?? '';
      DiscountFeeName = this.props.UserDetail?.settings?.virtualTerminalSettings.DiscountFeeName ?? '';
      InvoiceSalesTaxFee = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSalesTaxFee ?? 0;
      InvoiceSalesTaxFeeType = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSalesTaxFeeType ?? '';
    } else {
      this.props.history.push('/Invoices');
    }

    const customInvoicePageFields = locationDetails?.customInvoicePageFields;
    const {
      system,
      resendTimeText,
      firstTimeText,
      timestamp_PAID,
      dateOpened,
      startAddress,
      endAddress,
      invoice,
      STATUS,
      email,
      problemType,
      pickUpLocation,
      pickUpNotes,
      amount,
      discountFeeName,
      distanceDetails,
      notes,
      serviceType,
      field1,
      field2,
      field3,
      field4,
      field5,
      field6,
      field7,
    } = this.props.input;

    const SERVICE_TYPE = ['Fuel/Fluids', 'Jump Start', 'Lockout', 'Towing', 'Tire Change'];
    const choices = ['YET_TO_PAY', 'VISITED', 'PAID', 'DISPATCHED'];
    const problem = [
      ``,
      `Won't Start`,
      `Belt Broken`,
      `Brakes`,
      `Engine Fire`,
      `Engine Problem`,
      `Fuel System Problem`,
      `Head / Brake Lights`,
      `Ignition Problems`,
      `Items Hanging`,
      `Key Stuck in Ignition`,
      `Multiple Tire / No Spare`,
      `Oil / Fuel Leak`,
      `Overheating`,
      `	Stuck in Park / Gear`,
      `Tire Pressure Low`,
      `Transmission Problem`,
      `Vandalism`,
      `Windshield (Cracked / Broken)`,
      `Other`,
    ];
    const MAKE = [
      `AM General`,
      `AMC`,
      `Acura`,
      `Alfa Romeo`,
      `Aston Martin`,
      `Audi`,
      `BACKDRAFT`,
      `BMW`,
      `Bentley`,
      `Buick`,
      `Cadillac`,
      `Chevrolet`,
      `Chrysler`,
      `Daewoo`,
      `Daihatsu`,
      `Datsun`,
      `Dodge`,
      `EAGLE`,
      `EXCALIBUR`,
      `FIAT`,
      `Ferrari`,
      `Ford`,
      `GEO`,
      `GMC`,
      `GRUMMAN`,
      `Honda`,
      `Hummer`,
      `Hyundai`,
      `INTERNATIONAL`,
      `Infiniti`,
      `Isuzu`,
      `Jaguar`,
      `Jeep`,
      `Kia`,
      `Lamborghini`,
      `Land Rover`,
      `Lexus`,
      `Lincoln`,
      `Lotus`,
      `MARUTI`,
      `MG`,
      `Maserati`,
      `Maybach`,
      `Mazda`,
      `Mercedes Benz`,
      `Mercury`,
      `Merkur`,
      `Mini`,
      `Mitsubishi`,
      `Nissan`,
      `OTHER`,
      `Oldsmobile`,
      `Peugeot`,
      `Plymouth`,
      `Pontiac`,
      `Porsche`,
      `Rolls Royce`,
      `Saab`,
      `Saturn`,
      `Scion`,
      `Smart`,
      `Sterling`,
      `Subaru`,
      `Suzuki`,
      `Tesla`,
      `Toyota`,
      `Volkswagen`,
      `Volvo`,
      `WHEEGO`,
      `WILLYS`,
      `Yugo`,
    ];
    const COLOR = [
      `BEIGE`,
      `BLACK`,
      `BLUE`,
      `BROWN`,
      `BURGUNDY`,
      `CHAMPAGNE`,
      `GOLD`,
      `GRAY`,
      `GRAY-VIOLET`,
      `GREEN`,
      `LIGHT BLUE`,
      `LIGHT BROWN`,
      `LIGHT GRAY`,
      `LIGHT GREEN`,
      `MAROON`,
      `OLIVE-GREEN`,
      `ORANGE`,
      `PEARL-WHITE`,
      `PEWTER`,
      `PINK`,
      `PURPLE`,
      `RED`,
      `SAND`,
      `SILVER`,
      `TAN`,
      `TEAL`,
      `WHITE`,
      `YELLOW`,
    ];

    const { setInputState, t } = this.props;
    return (
      <div className="rightPanel">
        <Topheader />
        <Container component="main">
          {this.state.misMatchError.map((e, id) => (
            <ErrorMessage errors={[e]} key={id} />
          ))}

          {this.state.successMessages ? <SuccessMessage successes={[this.props.info.invoiceInfo.data.message]} /> : ''}
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            {/* <Paper className={classes.pt_1}> */}
            <form autoComplete={'none'}>
              <SnackbarContent
                className={system === 'SYSTEM 1' ? classes.mySnackbarRed : classes.mySnackbarBlue}
                message={'Submit this purchase order into DISPATCH ' + system}
              />

              <h2 className={classes.form_heading}> {'Invoice Overview'} </h2>
              <Grid container alignItems="flex-start" style={{ width: '100%', textAlign: 'left' }}>
                <Grid item container alignItems="flex-start" xs={12} sm={6}>
                  <Grid item xs={12} sm={5} className="ml_2">
                    <Textfield
                      className={classNames([classes.invoice_wth, classes.pl_1])}
                      id={'invoice'}
                      type={'number'}
                      value={invoice}
                      labels={t('Invoice')}
                      onChange={(evt) => this.textHandler(evt)}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} className="ml_2">
                    <Textfield
                      className={classNames([classes.invoice_wth, classes.pl_1])}
                      id={'amount'}
                      type={'text'}
                      value={amount}
                      labels={t('Amount')}
                      onChange={(evt) => this.textHandler(evt)}
                      disabled={timestamp_PAID && STATUS === 'PAID' ? true : false}
                    />
                  </Grid>
                  {!this.isDualPricingEnabled() && InvoiceDiscount ? (
                    <Grid item xs={12} sm={5} className="ml_2">
                      <Textfield
                        className={classNames([classes.invoice_wth, classes.pl_1])}
                        id={'svcFee'}
                        type={'text'}
                        value={getSVCFee(InvoiceSVCFeeType, InvoiceSVCFee, amount)}
                        labels={(discountFeeName || DiscountFeeName) + ' (' + InvoiceSVCFee + InvoiceSVCFeeType + ')'}
                        disabled={true}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                  {this.isDualPricingEnabled() ? (
                    <Grid item xs={12} sm={5} className="ml_2">
                      <Textfield
                        className={classNames([classes.invoice_wth, classes.pl_1])}
                        id={'svcFee'}
                        type={'text'}
                        value={getRoadsideTotalAmount(
                          false,
                          InvoiceTax,
                          InvoiceSVCFeeType,
                          InvoiceSVCFee,
                          InvoiceSalesTaxFee,
                          InvoiceSalesTaxFeeType,
                          amount
                        )}
                        labels={t('CashTotal')}
                        disabled={true}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                  {InvoiceTax ? (
                    <Grid item xs={12} sm={5} className="ml_2">
                      <Textfield
                        className={classNames([classes.invoice_wth, classes.pl_1])}
                        id={'svcFee'}
                        type={'text'}
                        value={getSVCFee(InvoiceSalesTaxFeeType, InvoiceSalesTaxFee, amount)}
                        labels={'Sales Tax' + ' (' + InvoiceSalesTaxFee + InvoiceSalesTaxFeeType + ')'}
                        disabled={true}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid item xs={12} sm={5} className="ml_2">
                    <Textfield
                      className={classNames([classes.invoice_wth, classes.pl_1])}
                      id={'total'}
                      type={'text'}
                      value={
                        this.isDualPricingEnabled()
                          ? cardTotalAmountWhenDualPricingIsEnabled(
                              InvoiceDiscount,
                              InvoiceTax,
                              InvoiceSVCFeeType,
                              InvoiceSVCFee,
                              InvoiceSalesTaxFee,
                              InvoiceSalesTaxFeeType,
                              amount
                            )
                          : getRoadsideTotalAmount(
                              InvoiceDiscount,
                              InvoiceTax,
                              InvoiceSVCFeeType,
                              InvoiceSVCFee,
                              InvoiceSalesTaxFee,
                              InvoiceSalesTaxFeeType,
                              amount
                            )
                      }
                      labels={this.isDualPricingEnabled() ? t('CardTotal') : t('Total')}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} className="ml_2">
                    <Textfield
                      className={classNames([classes.invoice_wth, classes.pl_1])}
                      id={'pickUpLocation'}
                      type={'text'}
                      value={pickUpLocation}
                      labels={t('PickupLocation')}
                      onChange={(evt) => this.textHandler(evt)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} className="ml_2">
                    <Textfield
                      className={classNames([classes.invoice_wth, classes.pl_1])}
                      id={'pickUpNotes'}
                      type={'text'}
                      value={pickUpNotes}
                      labels={t('PickupNotes')}
                      onChange={(evt) => this.textHandler(evt)}
                    />
                  </Grid>
                  <Grid xs={12} sm={10} className="ml_2">
                    <Textfield
                      className={classNames([classes.invoice_wth, classes.pl_1])}
                      id={'notes'}
                      type={'text'}
                      value={notes}
                      labels={t('Notes')}
                      onChange={(evt) => this.textHandler(evt)}
                      multiline
                      rowsMax={5}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12} sm={6}>
                    <FormControl className={classNames([classes.mt_16, classes.mb_8, classes.w_100])}>
                      <InputLabel shrink htmlFor="age-label-placeholder">
                        Status
                      </InputLabel>
                      <Select
                        value={STATUS ? STATUS : ''}
                        name="STATUS"
                        inputProps={{ id: 'STATUS-required' }}
                        style={{ marginRight: '1rem' }}
                        input={<Input name="age" id="age-label-placeholder" />}
                        onChange={(event) => {
                          setInputState(event.target.name, event.target.value);
                        }}
                      >
                        <MenuItem value="">
                          {' '}
                          <em>{''}</em>{' '}
                        </MenuItem>
                        {RenderMenuItem(choices)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl className={classNames([classes.mt_16, classes.mb_8, classes.w_100])}>
                      <InputLabel shrink htmlFor="age-label-placeholder">
                        Service type
                      </InputLabel>
                      <Select
                        value={serviceType}
                        name="serviceType"
                        inputProps={{ id: 'serviceType-required' }}
                        style={{ marginRight: '1rem' }}
                        onChange={(event) => {
                          setInputState(event.target.name, event.target.value);
                        }}
                      >
                        <MenuItem value="">
                          {' '}
                          <em>{''}</em>{' '}
                        </MenuItem>
                        {RenderMenuItem(SERVICE_TYPE)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl className={classNames([classes.mt_16, classes.mb_8, classes.w_100])}>
                      <InputLabel shrink htmlFor="age-label-placeholder">
                        Problem type
                      </InputLabel>
                      <Select
                        value={problemType}
                        name="problemType"
                        inputProps={{ id: 'problemType-required' }}
                        style={{ marginRight: '1rem' }}
                        onChange={(event) => {
                          setInputState(event.target.name, event.target.value);
                        }}
                      >
                        <MenuItem value="">
                          {' '}
                          <em>{''}</em>{' '}
                        </MenuItem>
                        {RenderMenuItem(problem)}
                      </Select>
                    </FormControl>
                  </Grid>
                  {customInvoicePageFields &&
                  field4.key === customInvoicePageFields[3].value &&
                  customInvoicePageFields[3].enabled ? (
                    <Grid item xs={12} sm={6}>
                      <Textfield
                        className={classes.invoice_wth}
                        id={'field4'} //Year
                        type={'text'}
                        value={field4.value}
                        labels={t(field4.key)}
                        onChange={(evt) => this.textHandler(evt, field4.key)}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                  {customInvoicePageFields &&
                  field6.key === customInvoicePageFields[5].value &&
                  customInvoicePageFields[5].enabled ? (
                    <Grid item xs={12} sm={6}>
                      <FormControl className={classNames([classes.mt_16, classes.mb_8, classes.w_100])}>
                        <InputLabel shrink htmlFor="age-label-placeholder">
                          {field6.key}
                        </InputLabel>
                        <Select
                          value={field6.value}
                          name={'field6'} //Make
                          inputProps={{ id: 'make-required' }}
                          style={{ marginRight: '1rem' }}
                          onChange={(event) => {
                            setInputState(event.target.name, event.target.value, field6.key);
                          }}
                        >
                          <MenuItem value="">
                            {' '}
                            <em>{''}</em>{' '}
                          </MenuItem>
                          {RenderMenuItem(field6.key.toUpperCase() === 'MAKE' ? MAKE : COLOR)}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {customInvoicePageFields &&
                  field5.key === customInvoicePageFields[4].value &&
                  customInvoicePageFields[4].enabled ? (
                    <Grid item xs={12} sm={6}>
                      <Textfield
                        className={classes.invoice_wth}
                        id={'field5'} //Model
                        type={'text'}
                        value={field5.value}
                        labels={t(field5.key)}
                        onChange={(evt) => this.textHandler(evt, field5.key)}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                  {customInvoicePageFields &&
                  field7.key === customInvoicePageFields[6].value &&
                  customInvoicePageFields[6].enabled ? (
                    <Grid item xs={12} sm={6}>
                      <FormControl className={classNames([classes.mt_16, classes.mb_8, classes.w_100])}>
                        <InputLabel shrink htmlFor="age-label-placeholder">
                          {field7.key}
                        </InputLabel>
                        <Select
                          value={field7.value}
                          name={'field7'} //Color
                          inputProps={{ id: 'color-required' }}
                          style={{ marginRight: '1rem' }}
                          onChange={(event) => {
                            setInputState(event.target.name, event.target.value, field7.key);
                          }}
                        >
                          <MenuItem value="">
                            {' '}
                            <em>{''}</em>{' '}
                          </MenuItem>
                          {RenderMenuItem(field7.key.toUpperCase() === 'MAKE' ? MAKE : COLOR)}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid item xs={12} sm={11}>
                    <TextField
                      id="standard-full-width"
                      label="Date opened"
                      style={{ marginTop: '2rem' }}
                      value={dateOpened || ''}
                      // helperText="Full width!"
                      fullWidth
                      // margin="normal"
                      disabled
                    />
                    <TextField
                      label="Date Of Payment"
                      id="standard-full-width"
                      value={timestamp_PAID || ''}
                      placeholder="Time of Payment"
                      fullWidth
                      disabled
                    />
                    <TextField id="standard-full-width" value={firstTimeText || ''} fullWidth disabled />
                    <TextField id="standard-full-width" value={resendTimeText || ''} fullWidth disabled />
                  </Grid>
                </Grid>
              </Grid>

              <h2 className={classes.form_heading}> {'Contact Information'} </h2>
              <Grid container style={{ width: '100%' }}>
                <Grid item container alignItems="flex-start" xs={12} sm={6}>
                  {customInvoicePageFields &&
                  field1.key === customInvoicePageFields[0].value &&
                  customInvoicePageFields[0].enabled ? (
                    <Grid item xs={12} sm={6} className="ml_2">
                      <Textfield
                        className={classNames([classes.invoice_wth, classes.pl_1])}
                        id={'field1'} //First Name
                        type={'text'}
                        value={field1.value}
                        labels={t(field1.key)}
                        onChange={(evt) => this.textHandler(evt, field1.key)}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                  {customInvoicePageFields &&
                  field2.key === customInvoicePageFields[1].value &&
                  customInvoicePageFields[1].enabled ? (
                    <Grid item xs={12} sm={6}>
                      <Textfield
                        className={classes.invoice_wth}
                        id={'field2'} //Last Name
                        type={'text'}
                        value={field2.value}
                        labels={t(field2.key)}
                        onChange={(evt) => this.textHandler(evt, field2.key)}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                  {customInvoicePageFields &&
                  field3.key === customInvoicePageFields[2].value &&
                  customInvoicePageFields[2].enabled ? (
                    <Grid item xs={12} className="ml_2">
                      <Textfield
                        className={classNames([classes.invoice_wth, classes.pl_1])}
                        id={'field3'} //Phone
                        type={'number'}
                        value={field3.value}
                        labels={t(field3.key)}
                        onChange={(evt) => this.textHandler(evt, field3.key)}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>

                <Grid item xs={12} sm={6} className="ml_2">
                  <Textfield
                    className={classNames([classes.invoice_wth, classes.pl_1])}
                    id={'email'}
                    type={'email'}
                    value={email}
                    labels={t('Email')}
                    onChange={(evt) => this.textHandler(evt)}
                  />
                  <br /> <br />
                  <Grid container style={{ width: '100%' }}>
                    <Grid item xs={12} sm={6} className={classNames([classes.text_left, classes.pl_1])}>
                      <Button variant="contained" color="primary" onClick={(e) => this.handleSubmitEmailInvoice(e)}>
                        Resend Pay Link
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <RadioGroup
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        aria-label="Options"
                        name="gender2"
                        value={this.state.resendPaymentLink}
                        onChange={(event) => setInputState('resendPaymentLink', event.target.value)}
                      >
                        <FormControlLabel
                          value="phone"
                          control={<Radio color="primary" />}
                          label="Phone"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value="email"
                          control={<Radio color="primary" />}
                          label="Email"
                          labelPlacement="start"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <br />

              <h2 className={classes.form_heading}> {'Location Info.'} </h2>
              <Grid container style={{ width: '100%' }}>
                <Grid item container alignItems="flex-start" xs={12} sm={6} className="ml_2">
                  <Grid item xs={11} className="ml_2">
                    <Textfield
                      className={classNames([classes.invoice_wth, classes.pl_1])}
                      id={'startAddress'}
                      type={'text'}
                      value={startAddress}
                      labels={t('Pickup Address')}
                      onChange={(evt) => this.textHandler(evt)}
                      disabled={true}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={11} className="ml_2">
                    <Textfield
                      className={classNames([classes.invoice_wth, classes.pl_1])}
                      id={'endAddress'}
                      type={'text'}
                      value={endAddress}
                      labels={t('Destination')}
                      onChange={(evt) => this.textHandler(evt)}
                      disabled={true}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  {serviceType === 'Towing' ? (
                    <Grid item xs={11} className="ml_2">
                      <Textfield
                        className={classNames([classes.invoice_wth, classes.pl_1])}
                        id={'distance'}
                        type={'text'}
                        value={distanceDetails.distance}
                        labels={t('Distance')}
                        onChange={(evt) => this.textHandler(evt)}
                        disabled={true}
                        style={{ width: '100%' }}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <br /> <br />
                  <Grid container style={{ width: '100%' }}>
                    <Grid item xs={12} sm={6} className={classNames([classes.text_left, classes.pl_1])}>
                      <Button variant="contained" color="primary" onClick={(e) => this.handleSubmitEmailInvoice(e)}>
                        Resend Receipt
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <RadioGroup
                        aria-label="Options"
                        name="resendReceipt"
                        value={this.state.resendReceipt}
                        onChange={(event) => setInputState('resendReceipt', event.target.value)}
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                      >
                        <FormControlLabel
                          value="phone"
                          control={<Radio color="primary" />}
                          label="Phone"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value="email"
                          control={<Radio color="primary" />}
                          label="Email"
                          labelPlacement="start"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  {this.state.status === 'PAID' ? (
                    <div>
                      <br />
                      <center>
                        <strong style={{ color: 'red' }}> Make sure you dispatch as the invoice has been paid </strong>
                      </center>
                    </div>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                className={classNames([classes.text_left, classes.pl_1, classes.mt_30, classes.pb_1])}
              >
                <Buttons
                  className={'emailInvoiceTerminal'}
                  variant="contained"
                  color="primary"
                  text={t('Update')}
                  onClick={(e) => this.handleUpdate(e)}
                />
                <Buttons
                  className={'emailInvoiceTerminal'}
                  variant="contained"
                  color="secondary"
                  text={t('Back')}
                  onClick={() => this.props.history.push('/Invoices')}
                />
              </Grid>
            </form>
            {/* </Paper> */}
          </LoadingOverlay>
        </Container>
      </div>
    );
  }
}

InvoiceEdit.propTypes = {
  t: PropTypes.func,
  match: PropTypes.any,
  InvoiceDetailById: PropTypes.func,
  history: PropTypes.any,
  intakeInvoiceLink: PropTypes.func,
  input: PropTypes.any,
  updateFormData: PropTypes.any,
  setInputState: PropTypes.any,
  info: PropTypes.any,
  UserDetail: PropTypes.any,
  locationDetails: PropTypes.any,
};

// const mapStateToProps = state => { return state; };

function mapStateToProps(state) {
  return {
    input: state.InputReducer,
    info: state.InvoiceReducer,
    UserDetail: state.AdminUserReducer,
    locationDetails: state.LocationReducer,
  };
}

export default connect(mapStateToProps, { updateFormData, InvoiceDetailById, intakeInvoiceLink, setInputState })(
  withTranslation()(InvoiceEdit)
);
