import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Textfield } from './../../components/Textfield';
import Tabs from '@material-ui/core/Tabs';
import { Divider, Modal } from '@mui/material';
import Tab from '@material-ui/core/Tab';
import { Buttons } from './../../components/Buttons';
import { Close } from '@mui/icons-material';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Topheader } from './../../components/Topheader';
import { UserDetailById, userSettings } from '../../actions/AdminUserAction';
import { companySettings, companyTermCondition } from '../../actions/CompanyAction';
import { addPaxTerminalsForLocation, locationDetailById } from '../../actions/LocationAction';
import { companyTermsConditions } from '../../actions/TermConditionsAction';
import LoadingOverlay from 'react-loading-overlay';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import classesSe from './Settings.module.scss';
import { Checkbox, Grid } from '@material-ui/core';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import validator from '../../helpers/checkValidations';
import { DialogBox } from './../../components/DialogBox/DialogBox';
import TextfieldLable from './TextfieldLable';
import { makePhoneNumberForDB } from '../../helpers/util';
import 'react-quill/dist/quill.snow.css';
import { IOSSwitch } from '../../components/common/IOSSwitch';
// components
import Switch from './components/Switch';
import Fee from './components/Fee';
import rates from './data/rates';
import './Settings.css';
import { CloseTwoTone } from '@material-ui/icons';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    '@media (max-width:768px)': {
      display: 'block',
      alignItems: 'center',
    },
  },
  MuiSelected: {
    background: '#eeeeee',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  mT1: {
    marginTop: `-1.5rem`,
    '@media (max-width:768px)': {
      marginTop: `0`,
    },
  },
  tab4: {
    marginTop: `-1.5rem`,
    marginRight: `-20rem`,
  },
  tab3: {
    marginTop: `-1.5rem`,
    marginRight: `-20rem`,
  },

  tabBtn: {
    '@media (max-width:768px)': {
      maxWidth: 'inherit',
    },
  },
  tpTableFullW: {
    width: '100%',
  },
  dropZone: {
    width: '50%',
    textAlign: 'center',
  },
  listErrors: {
    display: 'block',
  },
  closeButton: {
    cursor: 'pointer',
    marginTop: 62,
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      color: '#000000',
    },
  },
}));

const stateCodes = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  NewHampshire: 'NH',
  NewJersey: 'NJ',
  NewMexico: 'NM',
  NewYork: 'NY',
  NorthCarolina: 'NC',
  NorthDakota: 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  RhodeIsland: 'RI',
  SouthCarolina: 'SC',
  SouthDakota: 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  WestVirginia: 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};
const getFileNames = (state) => {
  const privacy_filename = state.privacy_policy ? state.privacy_policy?.name : state.privacy_filename;
  const refund_filename = state.refund_policy ? state.refund_policy?.name : state.refund_filename;
  return { privacy_filename, refund_filename };
};

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRTC: false,
      isPTC: false,
      isITC: false,
      isSPB: false,
      isPPB: false,
      isPATC: false,
      isCustomTowingBuild: JSON.parse(localStorage.getItem('locationPaydetails'))?.customTowingBuild || false,
      applyTaxLast: false,
      virtualTerminalSettings: {
        billingAddress: {
          addressOne: false,
          addressTwo: false,
          city: false,
          state: false,
          zip: false,
          isZipRequired: false,
          isBillingAddressRequired: false,
        },
        shippingAddress: {
          addressOne: false,
          addressTwo: false,
          city: false,
          state: false,
          zip: false,
          isZipRequired: false,
          isShippingAddressRequired: false,
        },
        surchargeByStateRates: {},
        surchargeByState: false,
        InvoiceTax: false,
        InvoiceSurcharge: false,
        InvoiceDiscount: false,
        DiscountFeeName: '',
        InvoiceSVCFeeType: '%',
        InvoiceSVCFee: 0,
        InvoiceSalesTaxFee: 0,
        InvoiceSalesTaxFeeType: '%',
        InvoiceSurchargeFeeType: '%',
        InvoiceSurchargeFee: 0,
        InvoiceSurchargeFeeName: 'Surcharge Fee',
      },
      invoiceSettings: {
        billingAddress: {
          addressOne: false,
          addressTwo: false,
          city: false,
          state: false,
          zip: false,
          isZipRequired: false,
          isBillingAddressRequired: false,
        },
        shippingAddress: {
          addressOne: false,
          addressTwo: false,
          city: false,
          state: false,
          zip: false,
          isZipRequired: false,
          isShippingAddressRequired: false,
        },

        CustomFeeOne: 0,
        CustomFeeOneEnabled: false,
        CustomFeeOneType: '%',
        CustomFeeOneName: '',
        CustomFeeOneDueDays: 0,

        CustomFeeTwo: 0,
        CustomFeeTwoEnabled: false,
        CustomFeeTwoType: '%',
        CustomFeeTwoName: '',
        CustomFeeTwoDueDays: 0,
      },
      privacy_policy: null,
      refund_policy: null,
      privacy_filename: null,
      refund_filename: null,
      receiptTermCondition: '',
      paymentTermCondition: '',
      paymentAuthTermCondition: '',
      selfPaymentBanner: '',
      paymentPageBanner: '',
      invoiceTermCondition: '',
      InvoiceDiscount: false,
      isPhoneNumber: false,
      declineTransactionNotify: false,
      InvoiceTax: false,
      checkedInvoice: true,
      InvoiceSalesTaxFee: 0,
      InvoiceSVCFee: 0,
      InvoiceSVCFeeType: '%',
      LateFee: 0,
      LateFeeType: '%',
      LateFeeName: '',
      DualFeeEnabled: false,
      InvoiceSalesTaxFeeType: '%',
      EditPanel: '',
      locationid:
        JSON.parse(localStorage.getItem('locationArrIds'))[0] !== null
          ? JSON.parse(localStorage.getItem('locationArrIds'))[0]
          : null,
      DiscountFeeName: '',
      usersData: [],
      receiptSetting: { email: '', phone: '', isPhone: false, isEmail: false },
      misMatchError: [],
      customFields: [],
      tab: 0,
      tabSelected: 'Transaction',
      title: 'Company',
      sideShow: true,
      isLoading: true,
      userDetails: JSON.parse(localStorage.getItem('user')),
      paxTerminals: [{ paxterminalName: '', paxTerminalSerial: '' }],
      error: {
        InvoiceSalesTaxFeeError: false,
        InvoiceSurchargeFeeError: false,
        InvoiceSurchargeFeeNameError: false,
        CustomFeeOneNameError: false,
        CustomFeeOneDueDaysError: false,
        CustomFeeOneError: false,
        CustomFeeTwoNameError: false,
        CustomFeeTwoError: false,
        InvoiceSVCFeeError: false,
        DualFeeError: false,
        amountError: false,
        phoneError: false,
      },
      isPaxTransactionEnable: false,
      isPaxDuplicateError: -1,
      isOpen: false,
      isModalOpen: false,
      tempStateRates: [],
      fileUploadError: false,
      billingAddressSwitchControl: [
        {
          label: 'Address 1',
          id: 'virtualTerminalSettings.billingAddress.addressOne',
          state: false,
        },
        {
          label: 'Address 2',
          id: 'virtualTerminalSettings.billingAddress.addressTwo',
          state: false,
        },
        {
          label: 'City',
          id: 'virtualTerminalSettings.billingAddress.city',
          state: false,
        },
        {
          label: 'State',
          id: 'virtualTerminalSettings.billingAddress.state',
          state: false,
        },
        {
          label: 'Zip',
          id: 'virtualTerminalSettings.billingAddress.zip',
          state: false,
        },
        {
          label: 'Zip Required',
          id: 'virtualTerminalSettings.billingAddress.isZipRequired',
          state: false,
        },
        {
          label: 'Require Billing Address',
          id: 'virtualTerminalSettings.billingAddress.isBillingAddressRequired',
          state: false,
        },
      ],
      shippingAddressSwitchControl: [
        {
          label: 'Address 1',
          id: 'virtualTerminalSettings.shippingAddress.addressOne',
          state: false,
        },
        {
          label: 'Address 2',
          id: 'virtualTerminalSettings.shippingAddress.addressTwo',
          state: false,
        },
        {
          label: 'City',
          id: 'virtualTerminalSettings.shippingAddress.city',
          state: false,
        },
        {
          label: 'State',
          id: 'virtualTerminalSettings.shippingAddress.state',
          state: false,
        },
        {
          label: 'Zip',
          id: 'virtualTerminalSettings.shippingAddress.zip',
          state: false,
        },
        {
          label: 'Zip Required',
          id: 'virtualTerminalSettings.shippingAddress.isZipRequired',
          state: false,
        },
        {
          label: 'Require shipping Address',
          id: 'virtualTerminalSettings.shippingAddress.isShippingAddressRequired',
          state: false,
        },
      ],
      invoiceBillingAddressSwitchControl: [
        {
          label: 'Address 1',
          id: 'invoiceSettings.billingAddress.addressOne',
          state: false,
        },
        {
          label: 'Address 2',
          id: 'invoiceSettings.billingAddress.addressTwo',
          state: false,
        },
        {
          label: 'City',
          id: 'invoiceSettings.billingAddress.city',
          state: false,
        },
        {
          label: 'State',
          id: 'invoiceSettings.billingAddress.state',
          state: false,
        },
        {
          label: 'Zip',
          id: 'invoiceSettings.billingAddress.zip',
          state: false,
        },
        {
          label: 'Zip Required',
          id: 'invoiceSettings.billingAddress.isZipRequired',
          state: false,
        },
        {
          label: 'Require Billing Address',
          id: 'invoiceSettings.billingAddress.isBillingAddressRequired',
          state: false,
        },
      ],
      invoiceShippingAddressSwitchControl: [
        {
          label: 'Address 1',
          id: 'invoiceSettings.shippingAddress.addressOne',
          state: false,
        },
        {
          label: 'Address 2',
          id: 'invoiceSettings.shippingAddress.addressTwo',
          state: false,
        },
        {
          label: 'City',
          id: 'invoiceSettings.shippingAddress.city',
          state: false,
        },
        {
          label: 'State',
          id: 'invoiceSettings.shippingAddress.state',
          state: false,
        },
        {
          label: 'Zip',
          id: 'invoiceSettings.shippingAddress.zip',
          state: false,
        },
        {
          label: 'Zip Required',
          id: 'invoiceSettings.shippingAddress.isZipRequired',
          state: false,
        },
        {
          label: 'Require shipping Address',
          id: 'invoiceSettings.shippingAddress.isShippingAddressRequired',
          state: false,
        },
      ],
    };
    this.handleModelChange = this.handleModelChange.bind(this);
    this.config = {
      placeholderText: 'Type here...',
      charCounterCount: false,
    };
    this.switchControl = [];
  }

  componentDidMount() {
    this.fetchDetailsData();
    this.fetchTermConditions();
    this.fetchLocationData();
    this.checkIfPaxTransactionEnable();

    this.switchControl = [
      {
        label: 'Phone Number Enable',
        id: 'isPhoneNumber',
        state: this.state.isPhoneNumber,
      },
      {
        label: 'Notify Declined Transactions',
        id: 'declineTransactionNotify',
        state: this.state.declineTransactionNotify,
      },
    ];
  }
  fetchTermConditions() {
    this.props.companyTermsConditions(this.state.company, this.state.locationid).then((res) => {
      if (res.payload) {
        this.setState(
          {
            isPTC: res.payload.isPaymentTermConditionEnabled,
            isRTC: res.payload.isReceiptTermConditionEnabled,
            isITC: res.payload.isInvoiceTermConditionEnabled,
            isSPB: res.payload.isSelfPaymentPageBannerEnabled,
            isPPB: res.payload.isPaymentPageBannerEnabled,
            isPATC: res.payload.isPaymentAuthTermConditionEnabled,
            paymentTermCondition: res.payload.paymentTermCondition ?? '',
            receiptTermCondition: res.payload.receiptTermCondition ?? '',
            invoiceTermCondition: res.payload.invoiceTermCondition ?? '',
            paymentAuthTermCondition: res.payload.paymentAuthTermCondition ?? '',
            selfPaymentBanner: res.payload.selfPaymentBanner ?? '',
            paymentPageBanner: res.payload.paymentPageBanner ?? '',
            privacy_filename: res.payload.privacy_policy,
            refund_filename: res.payload.refund_policy,
          },
          () => {
            this.forceUpdate();
          }
        );
      }
    });
  }
  checkIfPaxTransactionEnable() {
    const locationDetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    if (locationDetails.gateway === 'multipass' && locationDetails.paxTransactionEnable) {
      this.setState({ isPaxTransactionEnable: true });
    }
  }

  setShippingAddressSwitchControl() {
    console.log('is called');
    this.setState({
      shippingAddressSwitchControl: [
        {
          label: 'Address 1',
          id: 'virtualTerminalSettings.shippingAddress.addressOne',
          state: this.state.virtualTerminalSettings.shippingAddress.addressOne,
        },
        {
          label: 'Address 2',
          id: 'virtualTerminalSettings.shippingAddress.addressTwo',
          state: this.state.virtualTerminalSettings.shippingAddress.addressTwo,
        },
        {
          label: 'City',
          id: 'virtualTerminalSettings.shippingAddress.city',
          state: this.state.virtualTerminalSettings.shippingAddress.city,
        },
        {
          label: 'State',
          id: 'virtualTerminalSettings.shippingAddress.state',
          state: this.state.virtualTerminalSettings.shippingAddress.state,
        },
        {
          label: 'Zip',
          id: 'virtualTerminalSettings.shippingAddress.zip',
          state: this.state.virtualTerminalSettings.shippingAddress.zip,
        },
        {
          label: 'Zip Required',
          id: 'virtualTerminalSettings.shippingAddress.isZipRequired',
          state: this.state.virtualTerminalSettings.shippingAddress.isZipRequired,
        },
        {
          label: 'Require Shipping Address',
          id: 'virtualTerminalSettings.shippingAddress.isShippingAddressRequired',
          state: this.state.virtualTerminalSettings.shippingAddress.isShippingAddressRequired,
        },
      ],
    });
  }

  setBillingAddressSwitchControl() {
    this.setState({
      billingAddressSwitchControl: [
        {
          label: 'Address 1',
          id: 'virtualTerminalSettings.billingAddress.addressOne',
          state: this.state.virtualTerminalSettings.billingAddress.addressOne,
        },
        {
          label: 'Address 2',
          id: 'virtualTerminalSettings.billingAddress.addressTwo',
          state: this.state.virtualTerminalSettings.billingAddress.addressTwo,
        },
        {
          label: 'City',
          id: 'virtualTerminalSettings.billingAddress.city',
          state: this.state.virtualTerminalSettings.billingAddress.city,
        },
        {
          label: 'State',
          id: 'virtualTerminalSettings.billingAddress.state',
          state: this.state.virtualTerminalSettings.billingAddress.state,
        },
        {
          label: 'Zip',
          id: 'virtualTerminalSettings.billingAddress.zip',
          state: this.state.virtualTerminalSettings.billingAddress.zip,
        },
        {
          label: 'Zip Required',
          id: 'virtualTerminalSettings.billingAddress.isZipRequired',
          state: this.state.virtualTerminalSettings.billingAddress.isZipRequired,
        },
        {
          label: 'Require Billing Address',
          id: 'virtualTerminalSettings.billingAddress.isBillingAddressRequired',
          state: this.state.virtualTerminalSettings.billingAddress.isBillingAddressRequired,
        },
      ],
    });
  }

  setInvoiceBillingAddressSwitchControl() {
    this.setState({
      invoiceBillingAddressSwitchControl: [
        {
          label: 'Address 1',
          id: 'invoiceSettings.billingAddress.addressOne',
          state: this.state.invoiceSettings.billingAddress.addressOne,
        },
        {
          label: 'Address 2',
          id: 'invoiceSettings.billingAddress.addressTwo',
          state: this.state.invoiceSettings.billingAddress.addressTwo,
        },
        {
          label: 'City',
          id: 'invoiceSettings.billingAddress.city',
          state: this.state.invoiceSettings.billingAddress.city,
        },
        {
          label: 'State',
          id: 'invoiceSettings.billingAddress.state',
          state: this.state.invoiceSettings.billingAddress.state,
        },
        {
          label: 'Zip',
          id: 'invoiceSettings.billingAddress.zip',
          state: this.state.invoiceSettings.billingAddress.zip,
        },
        {
          label: 'Zip Required',
          id: 'invoiceSettings.billingAddress.isZipRequired',
          state: this.state.invoiceSettings.billingAddress.isZipRequired,
        },
        {
          label: 'Require Billing Address',
          id: 'invoiceSettings.billingAddress.isBillingAddressRequired',
          state: this.state.invoiceSettings.billingAddress.isBillingAddressRequired,
        },
      ],
    });
  }

  setInvoiceShippingAddressSwitchControl() {
    this.setState({
      invoiceShippingAddressSwitchControl: [
        {
          label: 'Address 1',
          id: 'invoiceSettings.shippingAddress.addressOne',
          state: this.state.invoiceSettings.shippingAddress.addressOne,
        },
        {
          label: 'Address 2',
          id: 'invoiceSettings.shippingAddress.addressTwo',
          state: this.state.invoiceSettings.shippingAddress.addressTwo,
        },
        {
          label: 'City',
          id: 'invoiceSettings.shippingAddress.city',
          state: this.state.invoiceSettings.shippingAddress.city,
        },
        {
          label: 'State',
          id: 'invoiceSettings.shippingAddress.state',
          state: this.state.invoiceSettings.shippingAddress.state,
        },
        {
          label: 'Zip',
          id: 'invoiceSettings.shippingAddress.zip',
          state: this.state.invoiceSettings.shippingAddress.zip,
        },
        {
          label: 'Zip Required',
          id: 'invoiceSettings.shippingAddress.isZipRequired',
          state: this.state.invoiceSettings.shippingAddress.isZipRequired,
        },
        {
          label: 'Require shipping Address',
          id: 'invoiceSettings.shippingAddress.isShippingAddressRequired',
          state: this.state.invoiceSettings.shippingAddress.isShippingAddressRequired,
        },
      ],
    });
  }

  fetchDetailsData() {
    this.props.UserDetailById({ id: this.state.userDetails._id }).then((res) => {
      if (res.type === 'USER_SUCCESS' && res.payload.userData.status === 200) {
        this.setState({ customFields: res.payload.userData.data.company.customFields });
        this.setState({
          termCondition: res.payload.userData.data.company.termCondition
            ? res.payload.userData.data.company.termCondition
            : '',
        });
        this.setState({ ...res.payload.userData.data.settings, isLoading: false });
        if (res.payload.userData.data.settings?.virtualTerminalSettings?.shippingAddress) {
          this.setState(
            {
              virtualTerminalSettings: {
                ...this.state.virtualTerminalSettings,
                shippingAddress: res.payload.userData.data.settings?.virtualTerminalSettings?.shippingAddress,
              },
            },
            () => {
              console.log('================', this.state.virtualTerminalSettings.shippingAddress);
              this.setShippingAddressSwitchControl();
            }
          );
        }
        if (res.payload.userData.data.settings?.virtualTerminalSettings?.billingAddress) {
          this.setState(
            {
              virtualTerminalSettings: {
                ...this.state.virtualTerminalSettings,
                billingAddress: res.payload.userData.data.settings?.virtualTerminalSettings?.billingAddress,
              },
            },
            () => {
              console.log('================billingAddress', this.state.virtualTerminalSettings.billingAddress);
              this.setBillingAddressSwitchControl();
            }
          );
        }
        if (res.payload.userData.data.settings?.invoiceSettings?.billingAddress) {
          this.setState(
            {
              invoiceSettings: {
                ...this.state.invoiceSettings,
                billingAddress: res.payload.userData.data.settings?.invoiceSettings?.billingAddress,
              },
            },
            () => {
              console.log('================billingAddress', this.state.invoiceSettings.billingAddress);
              this.setInvoiceBillingAddressSwitchControl();
            }
          );
        }
        if (res.payload.userData.data.settings?.invoiceSettings?.shippingAddress) {
          this.setState(
            {
              invoiceSettings: {
                ...this.state.invoiceSettings,
                shippingAddress: res.payload.userData.data.settings?.invoiceSettings?.shippingAddress,
              },
            },
            () => {
              console.log('================shippingAddress', this.state.invoiceSettings.shippingAddress);
              this.setInvoiceShippingAddressSwitchControl();
            }
          );
        }
        this.setState({ userDetails: res.payload.userData.data });
        this.setState({ user: this.state.userDetails._id });
        if (res.payload.userData.data.settings?.receiptSetting) {
          this.setState({
            receiptSetting: { ...this.state.receiptSetting, ...res.payload.userData.data.settings?.receiptSetting },
          });
        }
      }
    });
  }

  fetchLocationData() {
    this.props.locationDetailById(this.state.locationid).then((result) => {
      if (result.payload.locationData.data.success) {
        const { paxTerminals } = result.payload.locationData.data.response;
        this.setState({ paxTerminals: paxTerminals });
      }
    });
  }

  renderSwitch(param) {
    switch (param) {
      case 0:
        return 'Transactions';
      case 1:
        return 'User';
      case 3:
        return 'User';
      case 4:
        return 'User';
      case 2:
        return 'Locations';
      case 5:
        return 'Locations';
      case 6:
        return 'Locations';
      default:
        return '';
    }
  }
  handleChange(event, _t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'DiscountFeeName') {
      const restrictSpecialChars = /[*|":<>[\]{}`\\()';@&$.!#%,?^_+]/;
      const checkSpecialChars = restrictSpecialChars.test(value);
      if (checkSpecialChars) {
        return false;
      }
      if (value.length === 100) {
        return false;
      }
    }
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ [id]: value, error, successStr: '', misMatchError, isSubmit: false });
  }

  handleFeeNameChange(event, _t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    let vt = id.split('.');
    if (vt[1] === 'DiscountFeeName') {
      const restrictSpecialChars = /[*|":<>[\]{}`\\()';@&$.!#%,?^_+]/;
      const checkSpecialChars = restrictSpecialChars.test(value);
      if (checkSpecialChars) {
        return false;
      }
      if (value.length === 100) {
        return false;
      }
    }
    if (vt[1] === 'CustomFeeOneDueDays') {
      if (value === 0 || value === '0') {
        return false;
      }
    }
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState(
      { [vt[0]]: { ...this.state[vt[0]], [vt[1]]: value, error, successStr: '', misMatchError, isSubmit: false } },
      () => console.log(this.state[vt[0]][vt[1]])
    );
  }

  handlePrivacyPolicyChange(e) {
    console.log(e.target.files[0]?.type);
    let misMatchError = this.state.misMatchError;
    const search = misMatchError.indexOf('please select pdf file for privacy policy');

    if (e.target.files[0]?.type !== 'application/pdf') {
      if (search >= 0) {
        return;
      }
      misMatchError.push('please select pdf file for privacy policy');
    } else {
      if (search >= 0) {
        console.log('privacy', misMatchError, this.state.misMatchError);
        misMatchError.splice(search, 1);
      }
    }
    this.setState({ privacy_policy: e.target.files[0], misMatchError: misMatchError });
  }

  handleRefundPolicyChange(e) {
    console.log(e.target.files[0]);
    let misMatchError = this.state.misMatchError;
    const search = misMatchError.indexOf('please select pdf file for refund policy');

    if (e.target.files[0]?.type !== 'application/pdf') {
      if (search >= 0) {
        return;
      }
      misMatchError.push('please select pdf file for refund policy');
    } else {
      if (search >= 0) {
        console.log('refund', misMatchError, 'State', this.state.misMatchError, search);
        misMatchError.splice(search, 1);
      }
    }
    this.setState({ refund_policy: e.target.files[0], misMatchError: misMatchError });
  }

  handleChangeTab = (e, val, Id = null) => {
    if (this.props.location.search) {
      this.props.history.push(this.props.location.pathname);
    }
    let tabSelected = this.renderSwitch(val);
    console.log(val);
    console.log(tabSelected);
    switch (val) {
      case 0:
        this.setState({ successStr: '', AddUser: false, EditUser: true, tab: 0, tabSelected: tabSelected });

        this.props.history.push(this.props.location.pathname + '?users=' + Id);
        break;
      case 1:
        this.setState({ successStr: '', AddUser: true, EditUser: false, tab: 1, tabSelected: tabSelected });
        this.props.history.push(this.props.location.pathname);
        break;
      case 2:
        this.setState({ successStr: '', AddLocation: false, EditLocation: true, tab: 2, tabSelected: tabSelected });
        this.props.history.push(this.props.location.pathname + '?locations=' + Id);
        break;
      case 3:
        this.setState({ successStr: '', AddLocation: true, EditLocation: false, tab: 3, tabSelected: tabSelected });
        break;
      case 4:
        this.setState({ successStr: '', AddLocation: true, EditLocation: false, tab: 4, tabSelected: tabSelected });
        this.props.history.push(this.props.location.pathname);
        break;
      case 5:
        this.setState({ successStr: '', AddLocation: true, EditLocation: false, tab: 5, tabSelected: tabSelected });
        break;
      default:
        this.setState({
          successStr: '',
          tab: val,
          AddLocation: false,
          EditLocation: false,
          AddUser: false,
          EditUser: false,
          tabSelected: tabSelected,
        });
        break;
    }
  };

  handleChangeAddressSettings(e, name) {
    let vt = name.split('.');
    this.setState(
      { [vt[0]]: { ...this.state[vt[0]], [vt[1]]: { ...this.state[vt[0]][vt[1]], [vt[2]]: e.target.checked } } },
      () => {
        console.log(this.state[vt[0]][vt[1]]);
        this.setShippingAddressSwitchControl();
        this.setBillingAddressSwitchControl();
        this.setInvoiceBillingAddressSwitchControl();
        this.setInvoiceShippingAddressSwitchControl();
      }
    );
  }

  handleChangeTerminalID(e, name) {
    let [virtualTerminalSettings, setting] = name.split('.');

    if (setting === 'InvoiceTax' && this.state.DualFeeEnabled) {
      // When dual pricing is enabled, tax and apply tax last should be in sync
      this.setState(
        {
          [virtualTerminalSettings]: { ...this.state[virtualTerminalSettings], [setting]: e.target.checked },
          applyTaxLast: e.target.checked,
        },
        () =>
          console.log(
            `Tax and Apply Tax Last settings ${
              this.state[virtualTerminalSettings][setting] ? 'enabled' : 'disabled'
            } when dual pricing is on`
          )
      );
    } else {
      this.setState(
        { [virtualTerminalSettings]: { ...this.state[virtualTerminalSettings], [setting]: e.target.checked } },
        () => console.log(this.state[virtualTerminalSettings][setting])
      );
    }
  }

  handleApplyToggles(e, name) {
    if (this.state.DualFeeEnabled) {
      // When dual pricing is enabled, tax and apply tax last should be in sync
      this.setState(
        {
          [name]: e.target.checked,
          virtualTerminalSettings: { ...this.state.virtualTerminalSettings, InvoiceTax: e.target.checked },
        },
        () =>
          console.log(
            `Tax and Apply Tax Last settings ${
              this.state.applyTaxLast ? 'enabled' : 'disabled'
            } when dual pricing is on`
          )
      );
    } else {
      this.setState({ [name]: e.target.checked });
    }
  }

  handleChangeID(e, name) {
    let successMessages = false;
    let successStr = '';
    this.setState({ ...this.state, successStr, successMessages, [name]: e.target.checked });
  }
  decodeHTMLString(htmlString) {
    return htmlString
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'")
      .replace(/&nbsp;/g, ' ');
  }

  handleOpenModal() {
    this.setState({ ...this.state, isModalOpen: true });
  }

  handleCloseModal() {
    this.setState({ ...this.state, isModalOpen: false, tempStateRates: [] });
  }

  handleDownload() {
    const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(rates);
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', 'Surcharge Rate Template.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  handleSetSurchargeByState() {
    this.setState(
      {
        virtualTerminalSettings: {
          ...this.state.virtualTerminalSettings,
          surchargeByState: !this.state.virtualTerminalSettings.surchargeByState,
        },
      },
      () => {
        console.log('surchargeByState', this.state.virtualTerminalSettings.surchargeByState);
      }
    );
  }

  handleSaveFileAndSubmit() {
    this.setState(
      {
        ...this.state,
        isLoading: true,
        tempStateRates: [],
      },
      () => {
        this.handleCloseModal();
        this.handleSubmit();
      }
    );
  }

  handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      const dataArray = text.split('\n').map((row) => row.split(','));
      if (dataArray.length === 51 && dataArray[0].length === 4) {
        this.setState({
          ...this.state,
          tempStateRates: dataArray,
        });
        dataArray.shift();
        let rates = {};
        dataArray.map((rate) => {
          if (rate.length === 4) {
            rates[stateCodes[rate[0].replace(' ', '')]] = {
              state: stateCodes[rate[0].replace(' ', '')],
              application: rate[1] === 'TRUE',
              amount: rate[2],
              type: rate[3].replace('\r', ''),
            };
          }
        });
        console.log('rates', rates);
        this.setState({
          virtualTerminalSettings: {
            ...this.state.virtualTerminalSettings,
            surchargeByStateRates: rates,
          },
        });
      } else {
        this.setState({
          ...this.state,
          fileUploadError: true,
        });
      }
    };
    reader.readAsText(file);
  };

  handleAddMore(e) {
    let values = this.state.paxTerminals;
    values.push({ paxterminalName: '', paxTerminalSerial: '' });
    this.setState({ paxTerminals: values });
  }

  handleRemovePax(index) {
    let values = this.state.paxTerminals;
    values.splice(index, 1);
    console.log(values);
    this.setState({ paxTerminals: values });
  }

  handlePaxNameSerial(event, index) {
    const { id, value } = event.target;
    let data = [...this.state.paxTerminals];
    const isDuplicate = data.some((pax) => pax.paxTerminalSerial === value);
    if (isDuplicate) {
      this.setState({ isPaxDuplicateError: index, misMatchError: [`Pax serials can't be duplicate`] });
    } else if (!isDuplicate && this.state.isPaxDuplicateError) {
      this.setState({ isPaxDuplicateError: -1, misMatchError: [] });
    }
    data[index][id] = value;
    this.setState({ paxTerminals: data });
  }

  handleSubmit(_e, _t) {
    let location = JSON.parse(localStorage.getItem('selected'));
    let misMatchError = [];

    if (this.state.misMatchError.length > 0) {
      return null;
    }

    if (location === 0 && this.state.userDetails.permissions.allLocaton) {
      let msg = 'Select the location.';
      misMatchError.push(msg);
      this.setState({ misMatchError });
      window.scrollTo(0, 0);
      return false;
    }

    if (this.state.tab === 3) {
      if (this.state.isPATC && !this.state.paymentAuthTermCondition) {
        misMatchError.push('Please enter payment authorization term condition');
        this.setState({ misMatchError }, () => {
          setTimeout(() => {
            this.setState({ misMatchError: [] });
          }, 2000);
        });
        return;
      }
      let successMessages = false;
      this.setState({ isSubmit: true });

      let data = {
        user: this.state.user,
        company: this.state.company,
        termCondition: this.state.termCondition,
        invoiceTermCondition: this.state.invoiceTermCondition.replace(/<[^>]+>/g, '') ?? '',
        receiptTermCondition: this.state.receiptTermCondition.replace(/<[^>]+>/g, '') ?? '',
        paymentTermCondition: this.decodeHTMLString(this.state.paymentTermCondition.replace(/<[^>]+>/g, '')) ?? '',
        selfPaymentBanner: this.decodeHTMLString(this.state.selfPaymentBanner.replace(/<[^>]+>/g, '')) ?? '',
        paymentPageBanner: this.decodeHTMLString(this.state.paymentPageBanner.replace(/<[^>]+>/g, '')) ?? '',
        paymentAuthTermCondition: this.decodeHTMLString(this.state.paymentAuthTermCondition) ?? '',
        isPaymentTermConditionEnabled: this.state.isPTC,
        isReceiptTermConditionEnabled: this.state.isRTC,
        isInvoiceTermConditionEnabled: this.state.isITC,
        isSelfPaymentPageBannerEnabled: this.state.isSPB,
        isPaymentAuthTermConditionEnabled: this.state.isPATC,
        isPaymentPageBannerEnabled: this.state.isPPB,
        locationId: this.state.locationid,
      };
      this.setState({
        isSubmit: true,
        ...getFileNames(this.state),
      });
      const formData = new FormData();
      formData.append('privacy_policy', this.state.privacy_policy);
      formData.append('refund_policy', this.state.refund_policy);
      formData.append('data', JSON.stringify(data));

      this.props.companyTermCondition(formData).then((result) => {
        if (result !== undefined && result.type === 'COMPANY_SUCCESS') {
          window.scrollTo(0, 0);
          this.setState(
            {
              successMessages: true,
              successStr: result.payload.companyData ? result.payload.companyData.data.message : '',
            },
            () => {
              setTimeout(() => {
                this.setState({ successMessages: false, successStr: '' });
              }, 2000);
            }
          );
        }
        if (result !== undefined && result.type === 'COMPANY_ERROR') {
          this.setState({ misMatchError: this.props.info.infoCompany.data.message, successMessages });
        }
      });
    }
    if (this.state.tab === 5) {
      let successMessages = false;
      const data = {
        locationId: this.state.locationid,
        paxTerminals: this.state.paxTerminals,
      };

      this.props.addPaxTerminalsForLocation(data).then((result) => {
        if (result !== undefined && result.type === 'LOCATION_SUCCESS') {
          window.scrollTo(0, 0);
          this.setState(
            {
              successMessages: true,
              successStr: result.payload.locationData ? result.payload.locationData.data.message : '',
            },
            () => {
              setTimeout(() => {
                this.setState({ successMessages: false, successStr: '' });
              }, 2000);
            }
          );
        }
        if (result !== undefined && result.type === 'LOCATION_ERROR') {
          this.setState({ misMatchError: [result.payload.error.data.message], successMessages });
        }
      });
    }
    if (this.state.tab === 0 || this.state.tab === 1 || this.state.tab === 2 || this.state.tab === 4) {
      let successMessages = false;
      let state = this.state;
      this.setState({ isSubmit: true });
      if (state.virtualTerminalSettings.InvoiceDiscount && state.virtualTerminalSettings.DiscountFeeName === '') {
        alert('Enter fee name.');
        return false;
      }

      this.props.userSettings(state).then((result) => {
        if (result !== undefined && result.type === 'USER_SUCCESS') {
          window.scrollTo(0, 0);
          this.setState(
            {
              successMessages: true,
              successStr: this.props.info.userInfo ? this.props.info.userInfo.data.message : '',
              isLoading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ successMessages: false, successStr: '' });
              }, 2000);
            }
          );
        }
        if (result !== undefined && result.type === 'USER_ERROR') {
          this.setState({ misMatchError: this.props.info.userInfo.data.message, successMessages });
        }
      });
    }
  }
  handleReceiptTermsAndCondtions = (_event, value) => {
    this.setState({
      receiptTermCondition: value,
    });
  };
  handleModelPaymentTermnConditionChange = (_event, value) => {
    this.setState({
      paymentTermCondition: value,
    });
  };

  handlePaymentAuthTermConditionChange = (_event, value) => {
    this.setState({
      paymentAuthTermCondition: value,
    });
  };

  handleSelfPaymentBanner = (_event, value) => {
    this.setState({
      selfPaymentBanner: value,
    });
  };

  handlePaymentPageBanner = (_event, value) => {
    this.setState({
      paymentPageBanner: value,
    });
  };
  renderRTC(_t) {
    if (this.state.isRTC) {
      return (
        <Textfield
          required={true}
          id="receiptTermCondition"
          type="text"
          width={true}
          value={this.state.receiptTermCondition}
          onChange={this.handleReceiptTermsAndCondtions}
          variant="standard"
        />
      );
    }
  }
  renderITC(_t) {
    if (this.state.isITC) {
      return (
        <Textfield
          required={true}
          id="invoiceTermCondition"
          type="text"
          width={true}
          value={this.state.invoiceTermCondition}
          onChange={this.handleModelChange}
          variant="standard"
        />
      );
    }
  }
  renderPTC(_t) {
    if (this.state.isPTC) {
      return (
        <Textfield
          required={true}
          id="paymentTermCondition"
          type="text"
          width={true}
          value={this.state.paymentTermCondition}
          onChange={this.handleModelPaymentTermnConditionChange}
          variant="standard"
        />
      );
    }
  }
  renderPATC(_t) {
    if (this.state.isPATC) {
      return (
        <Textfield
          required={true}
          id="paymentAuthTermCondition"
          type="text"
          width={true}
          value={this.state.paymentAuthTermCondition}
          onChange={this.handlePaymentAuthTermConditionChange}
          variant="standard"
        />
      );
    }
  }
  renderSPB(_t) {
    if (this.state.isSPB) {
      return (
        <Textfield
          required={true}
          id="selfPaymentBanner"
          type="text"
          width={true}
          value={this.state.selfPaymentBanner}
          onChange={this.handleSelfPaymentBanner}
          variant="standard"
        />
      );
    }
  }

  renderPPB(_t) {
    if (this.state.isPPB) {
      return (
        <Textfield
          required={true}
          id="paymentPageBanner"
          type="text"
          width={true}
          value={this.state.paymentPageBanner}
          onChange={this.handlePaymentPageBanner}
        />
      );
    }
  }
  onchangeType(e, type) {
    let successMessages = false;
    let vt = type.split('.');
    this.setState({ [vt[0]]: { ...this.state[vt[0]], [vt[1]]: e.target.value, successMessages } }, () =>
      console.log(this.state[vt[0]][vt[1]])
    );
  }
  handleClose = () => {
    this.setState({ isOpen: false });
  };
  handleChangeCustom(event, t, i) {
    const values = [...this.state.customFields];
    values[i].value = event.target.value;
    this.setState({ customFields: values }, () => {});
  }

  handleLabels = (data) => {
    const values = [...this.state.customFields];
    values.push({ value: null, label: data.LableName });
    this.setState({ customFields: values });
  };
  handleRemove(i) {
    const values = [...this.state.customFields];
    values.splice(i, 1);
    this.setState({ customFields: values });
  }

  handleModelChange = (_event, value) => {
    this.setState({
      invoiceTermCondition: value,
    });
  };
  handleChangeReceipt(e) {
    let error = {};
    let misMatchError = [];
    let { id, value } = e.target;

    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    if (id === 'phone' || id === 'email') {
      this.setState({
        receiptSetting: { ...this.state.receiptSetting, [id]: value },
        error,
        successStr: '',
        misMatchError,
        isSubmit: false,
      });
    } else {
      this.setState({ [id]: value, error, successStr: '', misMatchError, isSubmit: false });
    }
  }
  handleChangeCheckbox(event) {
    this.setState({ receiptSetting: { ...this.state.receiptSetting, [event.target.id]: event.target.checked } });
  }

  isFeeEdit() {
    return this.state.userDetails.permissions.feeEdit && this.state.userDetails.permissions.feeEdit === true;
  }

  render() {
    const { t } = this.props;
    const { classes } = this.props;
    return (
      <div className="rightPanel">
        <Modal
          open={this.state.isModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="bulkInvoicesModal" style={{ maxWidth: '80%', maxHeight: '50vh', overflowY: 'auto' }}>
            <Close
              style={{ position: 'absolute', right: 25, cursor: 'pointer' }}
              onClick={() => this.handleCloseModal()}
            />
            <h3>Upload Files</h3>
            {this.state.tempStateRates.length === 0 && (
              <>
                <div className="drop_box ">
                  <header>
                    <h4>Select File here</h4>
                  </header>
                  <p> Files Supported: CSV, Max Size: 20MB</p>
                  {this.state.fileUploadError && <p style={{ color: 'red' }}> File does not have a valid format</p>}
                  <Typography
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      fontSize: 18,
                      fontWeight: '700',
                    }}
                  ></Typography>
                  <div
                    className="file-upload-wrapper"
                    data-text="Select your file."
                    style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                  >
                    <input
                      type="file"
                      name="file"
                      multiple={this.state.isModalOpen}
                      accept={'.csv'}
                      id="fileID"
                      // onChange={(e) => {
                      //   onChangeHandler(e);
                      //   getSelectedFileNames(e.target.files);
                      // }}
                      onChange={this.handleFileChange}
                      // ref={inputRef}
                      className="input-class file-upload-field "
                    />
                  </div>
                </div>
                <Buttons
                  id="saveSampleFile"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classesSe.submit}
                  onClick={() => this.handleDownload()}
                  text="Download Template"
                  disabled={this.state.misMatchError?.length > 0}
                />
              </>
            )}
            {this.state.tempStateRates.length !== 0 && (
              <>
                <div className="drop_box ">
                  <header>
                    <h4>Selected Rates</h4>
                  </header>
                  {/* <p> Files Supported:  CSV, Max Size: 20MB</p> */}
                  <Typography
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      fontSize: 18,
                      fontWeight: '700',
                    }}
                  ></Typography>
                  <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <table className="csv-table">
                      <thead>
                        <tr>
                          <th>State</th>
                          <th>Surcharge On</th>
                          <th>Fee Amount</th>
                          <th>Fee Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.tempStateRates.map((row, index) => (
                          <tr key={index}>
                            {row.map((cell, index) => (
                              <td key={index}>{cell}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <Buttons
                    id="saveSampleFile"
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classesSe.submit}
                    onClick={() => this.handleDownload()}
                    text="Download Template"
                    disabled={this.state.misMatchError?.length > 0}
                  />
                  <span style={{ marginLeft: '10px' }}></span>
                  <Buttons
                    id="saveFile"
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classesSe.submit}
                    onClick={() => this.handleSaveFileAndSubmit()}
                    text="Upload Surcharge Rates"
                    disabled={this.state.misMatchError?.length > 0}
                  />
                </div>
              </>
            )}
          </Box>
        </Modal>
        <Topheader />
        <DialogBox
          open={this.state.isOpen}
          handleClose={this.handleClose}
          message={<TextfieldLable handleClose={this.handleClose} handleLabels={this.handleLabels} />}
        />
        <h1 className="page-title">Settings </h1>
        <div className="settings-main">
          {this.state.misMatchError?.length > 0 ? (
            <ErrorMessage className={classes.listErrors} errors={this.state.misMatchError} />
          ) : (
            ''
          )}
          {this.state.successMessages && this.state.successStr ? (
            <SuccessMessage successes={[this.state.successStr]} />
          ) : (
            ''
          )}
          <div className={classes.root}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={this.state.tab}
              onChange={(e, val) => this.handleChangeTab(e, val)}
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              <Tab className={classes.tabBtn} label="Virtual Terminal" {...a11yProps(0)} />
              <Tab className={classes.tabBtn} label="Invoices" {...a11yProps(1)} />
              {this.isFeeEdit() ? <Tab className={classes.tabBtn} label="Fee Settings" {...a11yProps(2)} /> : ''}
              {/* <Tab className={classes.tabBtn} label="Users Settings" {...a11yProps(1)} /> */}
              <Tab className={classes.tabBtn} label="Term & Conditions" {...a11yProps(3)} />
              {this.state.company != '64e7d04fa9f2b90b558504f5' ? ( //hiding receipt settings for contractor nations for now
                <Tab className={classes.tabBtn} label="Receipt settings" {...a11yProps(4)} />
              ) : (
                ''
              )}
              {this.state.isPaxTransactionEnable ? (
                <Tab className={classes.tabBtn} label="Pax Terminals" {...a11yProps(5)} />
              ) : (
                ''
              )}
            </Tabs>
            <LoadingOverlay
              className={classes.tpTableFullW}
              active={this.state.isLoading}
              spinner
              text="Loading your content..."
            >
              <div className="tp-tabcustom">
                <TabPanel value={this.state.tab} index={0} className={classes.mT1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} className="vt-settings-container">
                      <div className="vt-settings-inner">
                        <h3 className="settings-heading">Add/Remove Fields</h3>
                        <Divider />
                        <h4 className="settings-heading">Address Settings</h4>
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            className="vt-settings-container"
                            data-cy="terminal-billingAddress"
                          >
                            <h4 className="settings-heading">Billing Address</h4>
                            {this.state.billingAddressSwitchControl.map((row, index) => (
                              <Switch
                                key={index}
                                label={row.label}
                                onChange={(e) => {
                                  this.handleChangeAddressSettings(e, row.id);
                                }}
                                checked={row.state}
                              ></Switch>
                            ))}
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            className="vt-settings-container"
                            data-cy="terminal-shippingAddress"
                          >
                            <h4 className="settings-heading">Shipping Address</h4>
                            {this.state.shippingAddressSwitchControl.map((row, index) => (
                              <Switch
                                key={index}
                                label={row.label}
                                onChange={(e) => {
                                  this.handleChangeAddressSettings(e, row.id);
                                }}
                                checked={row.state}
                              ></Switch>
                            ))}
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={12}
                      className="vt-settings-container"
                      data-cy="Terminal-PhoneNumber-NotifyDeclined"
                    >
                      <div className="vt-settings-inner">
                        <Grid container spacing={3}>
                          {this.switchControl.map((row, index) => (
                            <Switch
                              key={index}
                              label={row.label}
                              onChange={(e) => {
                                this.handleChangeID(e, row.id);
                              }}
                              checked={this.state[row.id]}
                            ></Switch>
                          ))}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={this.state.tab} index={1} className={classes.mT1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} className="vt-settings-container">
                      <div className="vt-settings-inner">
                        <h3 className="settings-heading">Add/Remove Fields</h3>
                        <Divider />
                        <h4 className="settings-heading">Address Settings</h4>
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            className="vt-settings-container"
                            data-cy="invoices-billingAddress"
                          >
                            <h4 className="settings-heading">Billing Address</h4>
                            {this.state.invoiceBillingAddressSwitchControl.map((row, index) => (
                              <Switch
                                key={index}
                                label={row.label}
                                onChange={(e) => {
                                  this.handleChangeAddressSettings(e, row.id);
                                }}
                                checked={row.state}
                              ></Switch>
                            ))}
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            className="vt-settings-container"
                            data-cy="invoices-shippingAddress"
                          >
                            <h4 className="settings-heading">Shipping Address</h4>
                            {this.state.invoiceShippingAddressSwitchControl.map((row, index) => (
                              <Switch
                                key={index}
                                label={row.label}
                                onChange={(e) => {
                                  this.handleChangeAddressSettings(e, row.id);
                                }}
                                checked={row.state}
                              ></Switch>
                            ))}
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={this.state.tab} index={2} className={classes.mT1}>
                  <Grid container spacing={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={12} className="vt-settings-container">
                        <div className="vt-settings-inner">
                          <h3 className="settings-heading">Fee Settings</h3>
                          <Divider />
                          <Grid container spacing={3}>
                            <Grid item xs={6} sm={6} md={6} className="vt-settings-container">
                              {this.isFeeEdit() && (
                                <Grid item xs={12} sm={10} md={12} className="vt-settings-container">
                                  <div className="vt-settings-inner">
                                    <Grid container spacing={3}>
                                      <Switch
                                        id="toggle-custom-fee-1"
                                        label="Custom Fee 1 (Applies to all payment types)"
                                        onChange={(e) => {
                                          this.handleChangeTerminalID(e, 'invoiceSettings.CustomFeeOneEnabled');
                                        }}
                                        checked={this.state.invoiceSettings.CustomFeeOneEnabled}
                                      ></Switch>
                                      <Fee
                                        nameLabel="Fee Name"
                                        error={this.state.error.CustomFeeOneNameError}
                                        id="invoiceSettings.CustomFeeOneName"
                                        nameValue={this.state.invoiceSettings.CustomFeeOneName}
                                        onNameChange={(e) => this.handleFeeNameChange(e, t)}
                                        typeLabel="Fee Type"
                                        typeValue={this.state.invoiceSettings.CustomFeeOneType}
                                        onTypeChange={(e) => {
                                          this.onchangeType(e, 'invoiceSettings.CustomFeeOneType');
                                        }}
                                        valueError={this.state.error.CustomFeeOneError}
                                        valueID="invoiceSettings.CustomFeeOne"
                                        valueLabel="Fee"
                                        amountValue={this.state.invoiceSettings.CustomFeeOne}
                                        onValueChange={(e) => this.handleFeeNameChange(e, t)}
                                      />
                                    </Grid>
                                  </div>
                                </Grid>
                              )}
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} className="vt-settings-container">
                              {this.isFeeEdit() && (
                                <Grid item xs={12} sm={10} md={12} className="vt-settings-container">
                                  <div className="vt-settings-inner">
                                    <Grid container spacing={3}>
                                      <Grid container spacing={3} direction="row">
                                        <Grid item md={5} sm={2} xs={5}>
                                          <Switch
                                            id="toggle-CustomFee"
                                            label="Custom Fee"
                                            onChange={(e) => {
                                              this.handleChangeTerminalID(e, 'virtualTerminalSettings.InvoiceDiscount');
                                            }}
                                            checked={this.state.virtualTerminalSettings.InvoiceDiscount}
                                          ></Switch>
                                        </Grid>
                                        <Grid item md={5} sm={2} xs={5}>
                                          <Switch
                                            id="toggle-DualPricing"
                                            label="Dual Pricing"
                                            onChange={(e) => {
                                              this.handleChangeID(e, 'DualFeeEnabled');
                                            }}
                                            checked={this.state.DualFeeEnabled}
                                          ></Switch>
                                        </Grid>
                                      </Grid>
                                      <Fee
                                        nameLabel="Fee Name"
                                        error={this.state.error.DiscountFeeNameError}
                                        id="virtualTerminalSettings.DiscountFeeName"
                                        nameValue={this.state.virtualTerminalSettings.DiscountFeeName}
                                        onNameChange={(e) => this.handleFeeNameChange(e, t)}
                                        typeLabel="Fee Type"
                                        typeValue={this.state.virtualTerminalSettings.InvoiceSVCFeeType}
                                        onTypeChange={(e) => {
                                          this.onchangeType(e, 'virtualTerminalSettings.InvoiceSVCFeeType');
                                        }}
                                        valueError={this.state.error.InvoiceSVCFeeError}
                                        valueID="virtualTerminalSettings.InvoiceSVCFee"
                                        valueLabel="SVC Fee"
                                        amountValue={this.state.virtualTerminalSettings.InvoiceSVCFee}
                                        onValueChange={(e) => this.handleFeeNameChange(e, t)}
                                      ></Fee>
                                    </Grid>
                                  </div>
                                </Grid>
                              )}
                            </Grid>
                            {this.isFeeEdit() && (
                              <Grid item sm={12} className="tax-select-field taxSelect">
                                <label className="CustomFeeOneDueDays">Apply Custom Fee 1:</label>
                                <Textfield
                                  style={{ width: '50px', marginTop: 7 }}
                                  required={true}
                                  error={this.state.error.CustomFeeOneDueDaysError}
                                  id="invoiceSettings.CustomFeeOneDueDays"
                                  type="text"
                                  value={this.state.invoiceSettings.CustomFeeOneDueDays}
                                  width={true}
                                  onChange={(e) => this.handleFeeNameChange(e, t)}
                                  focus={true}
                                  disabled={false}
                                />{' '}
                                <label className="CustomFeeOneDueDays">days after due date. </label>
                              </Grid>
                            )}
                            <Grid item xs={6} sm={10} md={6} className="vt-settings-container">
                              <div className="vt-settings-inner">
                                <Grid container spacing={3}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                      <Switch
                                        id="toggle-applySurcharge"
                                        label="Apply Surcharge"
                                        onChange={(e) => {
                                          this.handleChangeTerminalID(e, 'virtualTerminalSettings.InvoiceSurcharge');
                                        }}
                                        checked={this.state.virtualTerminalSettings.InvoiceSurcharge}
                                      ></Switch>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Switch
                                        id="toggle-applySurchargeByState"
                                        label="Apply Surcharge by State"
                                        onChange={() => {
                                          this.handleSetSurchargeByState();
                                        }}
                                        checked={this.state.virtualTerminalSettings.surchargeByState}
                                      ></Switch>
                                    </Grid>
                                  </Grid>
                                  <Fee
                                    nameLabel="Fee Name"
                                    error={this.state.error.InvoiceSurchargeFeeNameError}
                                    id="virtualTerminalSettings.InvoiceSurchargeFeeName"
                                    nameValue="Surcharge Fee"
                                    onNameChange={(e) => this.handleFeeNameChange(e, t)}
                                    typeLabel="Fee Type"
                                    typeValue={this.state.virtualTerminalSettings.InvoiceSurchargeFeeType}
                                    onTypeChange={(e) => {
                                      this.onchangeType(e, 'virtualTerminalSettings.InvoiceSurchargeFeeType');
                                    }}
                                    valueError={this.state.error.InvoiceSurchargeFeeError}
                                    valueID="virtualTerminalSettings.InvoiceSurchargeFee"
                                    valueLabel="Fee"
                                    amountValue={this.state.virtualTerminalSettings.InvoiceSurchargeFee}
                                    onValueChange={(e) => this.handleFeeNameChange(e, t)}
                                    disabled={true}
                                    allowTextField={!this.state.virtualTerminalSettings.surchargeByState}
                                  />
                                  {this.state.virtualTerminalSettings.surchargeByState && (
                                    <Buttons
                                      id="uploadSurcharge"
                                      type="button"
                                      variant="contained"
                                      color="primary"
                                      className={classesSe.submit}
                                      onClick={() => this.handleOpenModal()}
                                      text={t('Upload Surcharge Rates')}
                                      disabled={this.state.misMatchError?.length > 0}
                                    />
                                  )}
                                </Grid>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} className="vt-settings-container">
                              {this.isFeeEdit() && (
                                <Grid item xs={12} sm={10} md={12} className="vt-settings-container">
                                  <div className="vt-settings-inner">
                                    <Grid container spacing={3}>
                                      <Grid container spacing={3} direction="row">
                                        <Grid item md={5} sm={2} xs={5}>
                                          <Switch
                                            id="toggle-TaxEnable"
                                            label="Tax Enable"
                                            onChange={(e) => {
                                              this.handleChangeTerminalID(e, 'virtualTerminalSettings.InvoiceTax');
                                            }}
                                            checked={this.state.virtualTerminalSettings.InvoiceTax}
                                          ></Switch>
                                        </Grid>
                                        <Grid item md={5} sm={2} xs={5}>
                                          <Switch
                                            id="toggle-ApplyTaxLast"
                                            label="Apply Tax Last"
                                            onChange={(e) => {
                                              this.handleApplyToggles(e, 'applyTaxLast');
                                            }}
                                            checked={this.state.applyTaxLast}
                                          ></Switch>
                                        </Grid>
                                      </Grid>
                                      <Fee
                                        nameLabel="Fee Name"
                                        error={this.state.error.DiscountFeeNameError}
                                        id="virtualTerminalSettings.TaxFeeName"
                                        nameValue="Sales Tax"
                                        onNameChange={(e) => this.handleFeeNameChange(e, t)}
                                        typeLabel="Fee Type"
                                        typeValue={this.state.virtualTerminalSettings.InvoiceSalesTaxFeeType}
                                        onTypeChange={(e) => {
                                          this.onchangeType(e, 'virtualTerminalSettings.InvoiceSalesTaxFeeType');
                                        }}
                                        valueError={this.state.error.InvoiceSalesTaxFeeError}
                                        valueID="virtualTerminalSettings.InvoiceSalesTaxFee"
                                        valueLabel="Sales Tax Fee"
                                        amountValue={this.state.virtualTerminalSettings.InvoiceSalesTaxFee}
                                        onValueChange={(e) => this.handleFeeNameChange(e, t)}
                                        disabled={true}
                                      ></Fee>
                                    </Grid>
                                  </div>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={this.state.tab} index={3} className={classes.mT1}>
                  {/* <ReactQuill value={this.state.termCondition} onChange={this.handleModelChange} /> */}
                  <Box mb={2}>
                    <label style={{ marginRight: '16px', fontWeight: 'bolder' }}>Invoice Terms And Conditions</label>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          id="toggle-InvoiceTermCondition"
                          checked={this.state.isITC}
                          onChange={(e) => {
                            this.handleChangeID(e, 'isITC');
                          }}
                          value={this.state.isITC}
                        />
                      }
                    />
                  </Box>
                  {this.renderITC(t)}
                  <hr />
                  <Box mb={2}>
                    <label style={{ marginRight: '16px', fontWeight: 'bolder' }}>Receipt Terms And Conditions</label>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          id="toggle-ReceiptTermCondition"
                          checked={this.state.isRTC}
                          onChange={(e) => {
                            this.handleChangeID(e, 'isRTC');
                          }}
                          value={this.state.isRTC}
                        />
                      }
                    />
                  </Box>

                  {this.renderRTC(t)}
                  <hr />
                  <Box mb={2}>
                    <label style={{ marginRight: '16px', fontWeight: 'bolder' }}>
                      Payment Page Terms and Conditions
                    </label>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          id="toggle-PaymentPageTermCondition"
                          checked={this.state.isPTC}
                          onChange={(e) => {
                            this.handleChangeID(e, 'isPTC');
                          }}
                          value={this.state.isPTC}
                        />
                      }
                    />
                  </Box>
                  {this.renderPTC(t)}
                  <hr />
                  {this.state.isCustomTowingBuild && (
                    <>
                      <Box mb={2}>
                        <label style={{ marginRight: '16px', fontWeight: 'bolder' }}>
                          Payment Authorization Terms and Conditions
                        </label>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              id="toggle-PaymentAuthPageTermCondition"
                              checked={this.state.isPATC}
                              onChange={(e) => {
                                this.handleChangeID(e, 'isPATC');
                              }}
                              value={this.state.isPATC}
                            />
                          }
                        />
                      </Box>
                      {this.renderPATC(t)}
                      <hr />
                    </>
                  )}

                  <Box mb={2} style={{ display: 'flex', alignItems: 'center' }}>
                    <h4 id="self-payment-page-banner" style={{ marginRight: '16px', fontWeight: 'bolder' }}>
                      Self Payment Page Banner
                    </h4>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          id="toggle-SelfPayBanner"
                          checked={this.state.isSPB}
                          onChange={(e) => {
                            this.handleChangeID(e, 'isSPB');
                          }}
                          value={this.state.isSPB}
                        />
                      }
                    />
                  </Box>
                  {this.renderSPB(t)}
                  <Box mb={2} style={{ display: 'flex', alignItems: 'center' }}>
                    <h4 style={{ marginRight: '16px', fontWeight: 'bolder' }}>Payment Page Banner</h4>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          id="toggle-PaymentPageBanner"
                          checked={this.state.isPPB}
                          onChange={(e) => {
                            this.handleChangeID(e, 'isPPB');
                          }}
                          value={this.state.isPPB}
                        />
                      }
                    />
                  </Box>
                  {this.renderPPB(t)}
                  <Grid item xs={12} className="fee-name-field">
                    {/* <Grid item xs={6}> <label>Fee Name: </label> </Grid> */}
                    <Grid sm={6} className="tax-select-field taxSelect">
                      <label className="fee-name-label-settings">
                        Privacy Policy: <span style={{ color: '#3f51b5' }}>{this.state.privacy_filename}</span>{' '}
                      </label>
                      <input
                        id="privacy_policy"
                        name="privacy_policy"
                        accept="application/pdf"
                        type="file"
                        onChange={(e) => this.handlePrivacyPolicyChange(e, t)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="fee-name-field">
                    {/* <Grid item xs={6}> <label>Fee Name: </label> </Grid> */}
                    <Grid sm={6} className="tax-select-field taxSelect">
                      <label className="fee-name-label-settings">
                        Refund Policy: <span style={{ color: '#3f51b5' }}>{this.state.refund_filename}</span>
                      </label>
                      <input
                        id="refund_policy"
                        accept="application/pdf"
                        type="file"
                        onChange={(e) => this.handleRefundPolicyChange(e, t)}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={this.state.tab} index={4} className={classes.mT1}>
                  <Grid container item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={6}>
                        <Textfield
                          required={true}
                          error={this.state.error.phoneError}
                          id="phone"
                          type="text"
                          labels={t('Phone')}
                          value={this.state.receiptSetting ? this.state.receiptSetting.phone : ''}
                          width={true}
                          onChange={(e) => this.handleChangeReceipt(e, t)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Textfield
                          required={true}
                          error={this.state.error.emailError}
                          id="email"
                          type="text"
                          labels={t('Email')}
                          value={this.state.receiptSetting ? this.state.receiptSetting.email : ''}
                          width={true}
                          onChange={(e) => this.handleChangeReceipt(e, t)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label>Receipt Phone Number</label>
                        <Checkbox
                          id="isPhone"
                          labels="creatNewUser"
                          checked={this.state.receiptSetting.isPhone}
                          onChange={(e) => {
                            this.handleChangeCheckbox(e);
                          }}
                          value={this.state.receiptSetting.isPhone}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label>Receipt Email</label>
                        <Checkbox
                          id="isEmail"
                          labels="creatNewUser"
                          checked={this.state.receiptSetting.isEmail}
                          onChange={(e) => {
                            this.handleChangeCheckbox(e);
                          }}
                          value={this.state.receiptSetting.isEmail}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={this.state.tab} index={5} className={classes.mT1}>
                  <Grid container item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        {' '}
                        <h3>Add Terminals</h3>
                      </Grid>
                      {this.state.paxTerminals.map((terminal, index) => {
                        return (
                          <>
                            <Grid item xs={4} sm={4}>
                              <Textfield
                                id="paxterminalName"
                                type="text"
                                labels={t('Terminal Name')}
                                value={terminal.paxterminalName}
                                onChange={(e) => this.handlePaxNameSerial(e, index)}
                              />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                              <Textfield
                                error={this.state.isPaxDuplicateError === index}
                                helperText={
                                  this.state.isPaxDuplicateError === index
                                    ? `Pax Serial# ${terminal.paxTerminalSerial} can't be Duplicate`
                                    : ''
                                }
                                id="paxTerminalSerial"
                                key={`${terminal._id}`}
                                type="text"
                                labels={t('Pax Terminal Serial #')}
                                value={terminal.paxTerminalSerial}
                                onChange={(e) => this.handlePaxNameSerial(e, index)}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <CloseTwoTone
                                id="paxTerminal-serial"
                                className={classes.closeButton}
                                key={`${terminal.paxTerminalSerial}`}
                                onClick={(e) => this.handleRemovePax(index)}
                              />
                            </Grid>
                          </>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Buttons
                      id="paxTerminal-AddMore"
                      className={classes.ml_2}
                      variant="contained"
                      color="secondary"
                      text={t('Add More')}
                      onClick={(e) => this.handleAddMore(e)}
                    />
                  </Grid>
                </TabPanel>
              </div>
            </LoadingOverlay>
          </div>
          <div className="mt-4">
            <Buttons
              id="paxTerminal-Submit"
              type="button"
              variant="contained"
              color="primary"
              className={classesSe.submit}
              onClick={(e) => this.handleSubmit(e, t)}
              text={t('Save')}
              disabled={this.state.misMatchError?.length > 0}
            />
            <Buttons
              id="paxTerminal-Back"
              className={classesSe.ml_2}
              variant="contained"
              color="secondary"
              text={t('Back')}
              onClick={this.props.history.goBack}
            />
          </div>
        </div>
      </div>
    );
  }
}

Settings.propTypes = {
  t: PropTypes.func,
  UserDetailById: PropTypes.func,
  history: PropTypes.any,
  location: PropTypes.any,
  companyTermCondition: PropTypes.any,
  info: PropTypes.any,
  companySettings: PropTypes.func,
  userSettings: PropTypes.func,
  addPaxTerminalsForLocation: PropTypes.func,
  locationDetailById: PropTypes.func,
  classes: PropTypes.any,
  companyTermsConditions: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
    infoCompany: state.CompanyReducer,
    companyTermsConditions: state.TermsConditionReducer,
  };
}
const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles();
  return <Settings classes={classes} {...props} />;
};

export default connect(mapStateToProps, {
  UserDetailById,
  companySettings,
  companyTermCondition,
  userSettings,
  companyTermsConditions,
  addPaxTerminalsForLocation,
  locationDetailById,
})(withTranslation()(ApplyingStylesOnClasses));
