import React from 'react';
import PropTypes from 'prop-types';

import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { getRoadsideTotalAmount, getSVCFee } from '../../../../helpers/svcFeeAmount';
import { Textfield } from '../../../../components/Textfield';

import classes from './PaymentInfo.module.scss';

const GridWrapperComponent = ({ gridWidth, children }) => {
  return (
    <Grid className={classes.paymentInfoNumberBox} item xs={3} sm={3} md={gridWidth}>
      {children}
    </Grid>
  );
};

const SalesTaxComponent = ({ invoiceSalesTaxFee, invoiceSalesTaxFeeType, translator, baseAmount }) => {
  return (
    <GridWrapperComponent gridWidth={3}>
      <Textfield
        required={true}
        id="salesTax"
        type="text"
        labels={translator('Sales Tax') + ' (' + invoiceSalesTaxFee + invoiceSalesTaxFeeType + ')'}
        value={getSVCFee(invoiceSalesTaxFeeType, invoiceSalesTaxFee, baseAmount)}
        disabled={true}
      />
    </GridWrapperComponent>
  );
};

const PaymentInfoComponent = (props) => {
  const {
    isDualPricingEnabled,
    invoiceDiscount,
    discountFeeName,
    invoiceTax,
    invoiceSVCFee,
    invoiceSVCFeeType,
    invoiceSalesTaxFee,
    invoiceSalesTaxFeeType,
    translator,
    amountInputHandler,
    amount,
  } = props;

  const cardSubTotal =
    Number(amount) + (invoiceDiscount ? Number(getSVCFee(invoiceSVCFeeType, invoiceSVCFee, amount)) : 0);
  const cardTotal =
    Number(cardSubTotal) +
    (invoiceTax ? Number(getSVCFee(invoiceSalesTaxFeeType, invoiceSalesTaxFee, cardSubTotal)) : 0);

  return (
    <FormControl>
      <Grid container align="center">
        <GridWrapperComponent gridWidth={isDualPricingEnabled ? 4 : 2}>
          <FormControl>
            <Textfield
              id="amount"
              type="text"
              labels={translator(isDualPricingEnabled ? 'CashAmount' : 'Amount')}
              required={true}
              error={''} // TODO: do error handling
              value={amount}
              width={true}
              onChange={(evt) => amountInputHandler(evt)}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </FormControl>
        </GridWrapperComponent>

        {!isDualPricingEnabled && invoiceDiscount && (
          <GridWrapperComponent gridWidth={3}>
            <Textfield
              required={true}
              id="svcFee"
              type="text"
              labels={translator(discountFeeName) + ' (' + invoiceSVCFee + invoiceSVCFeeType + ')'}
              value={getSVCFee(invoiceSVCFeeType, invoiceSVCFee, amount)}
              disabled={true}
            />
          </GridWrapperComponent>
        )}

        {invoiceTax && (
          <SalesTaxComponent
            baseAmount={amount}
            translator={translator}
            invoiceSalesTaxFeeType={invoiceSalesTaxFeeType}
            invoiceSalesTaxFee={invoiceSalesTaxFee}
            isDualPricingEnabled={isDualPricingEnabled}
          />
        )}

        <GridWrapperComponent gridWidth={isDualPricingEnabled ? 4 : 2}>
          <Textfield
            id="totalAmount"
            value={getRoadsideTotalAmount(
              isDualPricingEnabled ? false : invoiceDiscount,
              invoiceTax,
              invoiceSVCFeeType,
              invoiceSVCFee,
              invoiceSalesTaxFee,
              invoiceSalesTaxFeeType,
              amount
            )}
            labels={translator(isDualPricingEnabled ? 'CashTotal' : 'Total')}
            fullWidth={!isDualPricingEnabled}
            disabled={true}
          />
        </GridWrapperComponent>
      </Grid>
      {isDualPricingEnabled && (
        <Grid container align="center">
          <GridWrapperComponent gridWidth={4}>
            <FormControl>
              <Textfield
                id="cardSubTotal"
                type="text"
                labels={translator('CardAmount')}
                fullWidth
                value={cardSubTotal || ''}
                disabled={true}
              />
            </FormControl>
          </GridWrapperComponent>

          {invoiceTax && (
            <SalesTaxComponent
              baseAmount={cardSubTotal}
              translator={translator}
              invoiceSalesTaxFeeType={invoiceSalesTaxFeeType}
              invoiceSalesTaxFee={invoiceSalesTaxFee}
              isDualPricingEnabled={isDualPricingEnabled}
            />
          )}

          <GridWrapperComponent gridWidth={4}>
            <Textfield
              id="totalAmount"
              value={cardTotal || ''}
              labels={translator('CardTotal')}
              fullWidth
              disabled={true}
            />
          </GridWrapperComponent>
        </Grid>
      )}
    </FormControl>
  );
};

GridWrapperComponent.propTypes = {
  children: PropTypes.element,
  gridWidth: PropTypes.number,
};

SalesTaxComponent.propTypes = {
  invoiceSalesTaxFee: PropTypes.number.isRequired,
  invoiceSalesTaxFeeType: PropTypes.string.isRequired,
  baseAmount: PropTypes.number.isRequired,
  translator: PropTypes.func.isRequired,
  isDualPricingEnabled: PropTypes.bool.isRequired,
};

PaymentInfoComponent.propTypes = {
  invoiceSVCFee: PropTypes.number.isRequired,
  invoiceSVCFeeType: PropTypes.string.isRequired,
  invoiceDiscount: PropTypes.bool.isRequired,
  discountFeeName: PropTypes.string.isRequired,
  invoiceTax: PropTypes.bool.isRequired,
  invoiceSalesTaxFee: PropTypes.number.isRequired,
  invoiceSalesTaxFeeType: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  translator: PropTypes.func.isRequired,
  amountInputHandler: PropTypes.func.isRequired,
  isDualPricingEnabled: PropTypes.bool.isRequired,
};

export default PaymentInfoComponent;
